import { Box, Container, Typography } from "@mui/material"
import React, { ReactNode } from "react"

type HeaderProperties = {
    title: string,
    children: ReactNode | undefined
}

export const Header:React.FC<HeaderProperties> = ({title, children}) => {
    return (
        <Box overflow="hidden" component="div" sx={ { 
            backgroundSize: 'cover', 
            backgroundPosition: 'left',
            display: "block"
        } }>
            <Container sx={ { 
                zIndex: "200",
                display: "block"
            } }>
                <Box sx={ {
                    paddingTop: "130px"
                } }>
                    <Typography variant="h1" gutterBottom>{ title }</Typography>
                </Box>
            </Container>
        </Box>
    )
}
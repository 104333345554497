import { Sectional } from "layout/Sectional";
import Splash from "layout/Splash";
import React from "react";

type PodcastProperties = {
    url: string
}

export const PodcastWidget: React.FC<PodcastProperties> = ({ url }) => {
    return (
        <>
            <Sectional>
                <iframe src={ url } style={{"width": "100%", "height": "505px", "border": "0 none"}} scrolling="no" title="Podcast">
                </iframe>
            </Sectional>
        </>
    );
}

import React from 'react';
import { useEffect, useRef } from 'react';
import { ShaderExperience } from './exhibits/ShaderExperience'
import { GeometryExperience } from './exhibits/GeometryExperience'
import { StateMachineExperience } from './exhibits/StateMachineExperience'
import { MADECanvasRenderer, MADEP5Renderer } from './Renderer';
import P5, { Shader } from 'p5';
import { NightlifeMapExperience as NightlifeMapExperience } from './exhibits/NightlifeMapExperience';
import { ExhibitStorage } from './exhibits/ExhibitStorage';

export const Canvas = props => {
    const canvasRef = useRef(null)
    const windowResizeHandler: (width:number, height:number) => any | undefined = props.windowHandler;

    useEffect(() => {
        const canvas:HTMLCanvasElement | null = canvasRef.current;
        if (canvas !== null) {
            const c = (canvas as HTMLCanvasElement)
            c.style.width = "100%";
            c.style.height = "100%";
            c.width = c.offsetWidth;
            c.height = c.offsetHeight;
            const renderer = props.renderer
            renderer.start(canvas)

            const handleResize = e => {
                c.style.width = "100%";
                c.style.height = "100%";
                if (windowResizeHandler !== undefined) {
                    windowResizeHandler(c.offsetWidth, c.offsetHeight);
                }
                console.log(`----------> the offsetWidth: ${c.offsetWidth}`)
            };

            window.addEventListener("resize", handleResize);
        }
    })


    return <canvas style={ { width: "100%" } } ref={canvasRef} {...props} />

}

export const ShaderCanvas = props => {
    const canvasRef = useRef(null)
    
    useEffect(() => {
        const canvas = canvasRef.current
        const renderer = props.renderer
        const fragmentShader = props.fragmentshader
        renderer.start(canvas, fragmentShader)
    }, [])

    return <canvas ref={canvasRef} {...props} />
}

export const ShaderCanvasMemo = React.memo(ShaderCanvas);

export const P5Container = props => {
    const divRef = useRef(null)
    
    useEffect(() => {
        const renderer = props.renderer
        renderer.start(divRef);
    })

    return <div id="p5-app" style={{ 
        width: "100%", 
        height: "100%", 
        margin: "0", 
        position: "absolute",
        overflow: "hidden"
    }} ref={divRef} {...props} />

}

export interface ExhibitExperience {
    // canvas: HTMLCanvasElement
    exhibitStorage: typeof ExhibitStorage
}

export class GeometryExhibitDelegate implements MADECanvasRenderer {
    canvas?: HTMLCanvasElement
    setSolved: React.Dispatch<React.SetStateAction<boolean>>;

    constructor(setSolved: React.Dispatch<React.SetStateAction<boolean>>) {
        this.setSolved = setSolved
    }

    start(canvas: HTMLCanvasElement) {
        this.canvas = canvas
        const exhibit = new GeometryExperience(canvas);
        exhibit.solvedCallback = this.setSolved
        if (ExhibitStorage.exhibit_one != undefined) {
            let solved:boolean = ExhibitStorage.exhibit_one as boolean
            this.setSolved(solved)
        }
        return true
    }
}


export class ShaderExhibitDelegate {
    canvas?: HTMLCanvasElement
    fragmentShader?: string
    setSolved: React.Dispatch<React.SetStateAction<boolean>>;
    static exhibit: ShaderExperience | undefined

    constructor(setSolved: React.Dispatch<React.SetStateAction<boolean>>) {
        this.setSolved = setSolved
    }

    recompileShader = (fragmentShader: string) => {
        if (ShaderExhibitDelegate.exhibit != undefined) {
            ShaderExhibitDelegate.exhibit.compile(fragmentShader)
        }
    }

    start(canvas: HTMLCanvasElement, fragmentShader: string) {
        this.canvas = canvas
        this.fragmentShader = fragmentShader
        ShaderExhibitDelegate.exhibit = new ShaderExperience(canvas, fragmentShader);
        ShaderExhibitDelegate.exhibit.solvedCallback = this.setSolved
        if (ExhibitStorage.exhibit_two != undefined) {
            let solved:boolean = ExhibitStorage.exhibit_two as boolean
            this.setSolved(solved)
        }
    }
}

export class StateMachineExhibitDelegate implements MADEP5Renderer {
    p5Container?: HTMLDivElement
    setSolved: React.Dispatch<React.SetStateAction<boolean>>

    constructor(setSolved: React.Dispatch<React.SetStateAction<boolean>>) {
        this.setSolved = setSolved
    }

    start(p5Container: HTMLDivElement) {
        this.p5Container = p5Container;

        const exhibit = new StateMachineExperience(this.p5Container)
        exhibit.setSolved = this.setSolved
        if (ExhibitStorage.exhibit_three) {
            this.setSolved(true)
        }

    }
}

export class MapExhibitDelegate implements MADEP5Renderer {
    p5Container?: HTMLDivElement

    start(p5Container: HTMLDivElement) {

        this.p5Container = p5Container;
        const exhibit = new NightlifeMapExperience(this.p5Container)
    }
}


import { Block } from "@mui/icons-material";
import { Box, Button, Container, createTheme, Grid, Typography, useMediaQuery } from "@mui/material";
import { getDesignTokens } from "AppGlobals";
import { Sectional } from "layout/Sectional";
import Splash from "layout/Splash";
import React from "react";


export const CollectionWidget: React.FC = () => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
        getDesignTokens(
            prefersDarkMode ? "dark" : "light")
        ),
        [
        prefersDarkMode ? "dark" : "light"
        ]
    );
  
    return (
        <Sectional title="Our Collection">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Button 
                        href="exhibits"
                        variant="contained"
                        sx={{
                            display: "block",
                            borderColor: theme.palette.secondary.light,
                            borderStyle: "solid",
                            borderWidth: "1px",
                            padding: "20px",
                            width: "100%"
                        }}
                    >
                        On the Floor
                        <Box sx={{
                            background: "url('/img/stock/atari_800xl.webp')",
                            backgroundSize: "cover",
                            marginTop: "10px",
                            width: "100%",
                            height: "300px"
                        }}>

                        </Box>
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button 
                        variant="contained"
                        href="/collection-storage"
                        sx={{
                            display: "block",
                            borderColor: theme.palette.secondary.light,
                            borderStyle: "solid",
                            borderWidth: "1px",
                            padding: "20px",
                            width: "100%"
                        }}
                    >
                        In the Collection
                        <Box sx={{
                            background: "url('img/stock/collection_back.webp')",
                            backgroundSize: "cover",
                            marginTop: "10px",
                            width: "100%",
                            height: "300px"
                        }}>

                        </Box>
                    </Button>
                </Grid>
            </Grid>
        </Sectional>
    );
}

import * as THREE from "three";
import {createImageMesh} from "./ImageMesh";
import {MAP_PIN_OFFSET, MAP_PIN_SCALE, MAP_PIN_TEXTURE} from "./constants";

export class MapPin {
  constructor(readonly x: number, readonly y: number, readonly qrCode: string) {}
}

const textureLoader = new THREE.TextureLoader();
const texturePromise = textureLoader.loadAsync(MAP_PIN_TEXTURE);

export abstract class MapLayer extends THREE.Object3D {
  abstract animate(cameraPosition: THREE.Vector3): void;
}

export class PinMapLayer extends MapLayer {
  async addPin(pin: MapPin) {
    const texture = await texturePromise;
    const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true, blending: THREE.NormalBlending, depthTest: false});
    const pinMesh = createImageMesh(texture, material, MAP_PIN_SCALE, MAP_PIN_OFFSET);

    pinMesh.position.set(pin.x, pin.y, 0);
    pinMesh.renderOrder = 1; // Ensure the pin is rendered last

    this.add( pinMesh );
  }

  animate(cameraPosition: THREE.Vector3) {
    // Update the z position of the pins based on the camera
    for(const child of this.children) {
        child.scale.setScalar(cameraPosition.z * 0.5)
    }
  }
}

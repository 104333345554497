import { Box, Button, Container, createTheme, Fade, Grid, ImageList, ImageListItem, makeStyles, Modal, Popover, useMediaQuery } from "@mui/material"
import { getDesignTokens } from "AppGlobals"
import { Sectional } from "layout/Sectional"
import Splash, { SplashColumn, SplashContent, SplashTitle } from "layout/Splash"
import React, { ReactNode, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

type RentalProperties = {
    data: {
        rentals: string
    }
}

type RentalSpace = {
    type: string,
    cost: {
        min_cost: number,
        max_cost: number,
        unit: string,
        min_duration: number,
        max_duration: number,
        included_stations: number,
        max_stations: number
    },
    images: string[],
    capacity: number,
    included_stations: number,
    max_stations: number
}

type RentalsData = {
    rentals: {
        spaces: RentalSpace[]
        stations: any[]
    }
}
interface Image {
    img: string;
    title: string;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    // width: '60%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const RentalWidget: React.FC<RentalProperties> = ({ data }) => {

    let params = useParams();
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('');

    const handleOpen = (image: Image) => {
        setImage(image.img);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);


    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [rentals, setRentals] = useState<RentalsData>();

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Cache-Control', 'no-cache');
    requestHeaders.set('Pragma', 'no-cache');
    requestHeaders.set('Expires', '0');

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
    getDesignTokens(
        prefersDarkMode ? "dark" : "light")
    ),
    [
        prefersDarkMode ? "dark" : "light"
    ]
    );

    useEffect(() => {
        fetch(data["rentals"], { 'headers': requestHeaders })
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setRentals(result);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
    }, [])

    let baseCost = (space: RentalSpace) => {
        return (
            <> 
                {
                    space.cost.min_cost === space.cost.max_cost ? 
                    <>${ space.cost.min_cost } per { space.cost.unit }</> : 
                    <>${ space.cost.min_cost } to ${ space.cost.max_cost } per { space.cost.unit }</>
                }
            </>
        );
    };

    if (rentals) {
        console.log(rentals);
        let key = 0;

        return (
            <Container>
                { 
                    rentals["rentals"]["spaces"].map((space: any) => {
                        return (
                            <Sectional key={ key++ } title={ space.type }>
                                <Grid container spacing={ 2 } >
                                    <Grid item {...{ xs: 12, sm: 6, md: 6 }} minHeight={160}>
                                        <ImageList variant="standard" cols={ 2 }>
                                            {
                                                space.images.map((item) => (
                                                    <ImageListItem key={item.img}>
                                                        <img
                                                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                                            alt={item.title}
                                                            onClick={(event) => handleOpen(item)}
                                                            style={{ cursor: 'pointer' }}
                                                            loading="lazy"
                                                        />
                                                    </ImageListItem>
                                                    
                                                ))
                                            }
                                        </ImageList>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Fade in={open} timeout={500}>
                                                <Box sx={style}>
                                                    <img
                                                        style={{ maxHeight: '70vh' }}
                                                        src={ image }
                                                        loading="lazy" />
                                                    <br />
                                                    <Button variant="contained" onClick={handleClose}>Close</Button>
                                                </Box>
                                            </Fade>
                                        </Modal>
                                    </Grid>
                                    <Grid item {...{ xs: 12, sm: 6, md: 6 }} minHeight={160}>
                                        <p>{ space["description"] }</p>
                                        { 
                                            space.cost.type === "rate" ? 
                                            <>
                                                <ul>
                                                    <li>Maximum Capacity: { space.capacity }</li>
                                                    <li>Included Private Exhibits: { space.included_stations }</li>
                                                    <li>Requestable Private Exhibits: { space.max_stations }</li>
                                                </ul>
                                                <hr />
                                                <ul>
                                                    Base Cost: { baseCost(space) }
                                                </ul>
                                            </>:
                                            <></>
                                        }
                                        <Button href={ space["link"] } variant="contained" color="secondary">Reserve {space.type} Now!</Button>
                                    </Grid>
                                </Grid>
                            </Sectional>
                        )
                    })
                }
            </Container>
        );

    }

    return (
        <>
        </>
    );
}
import { Box, Container, Typography, createTheme, useMediaQuery } from "@mui/material";
import { getDesignTokens } from "AppGlobals";
import React, { ReactNode } from "react";

type SectionalProperties = {
    title: string,
    children: ReactNode | undefined
}

export const ImageSectional:React.FC<SectionalProperties> = ({ title, children }) => {
   
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
        getDesignTokens(
        prefersDarkMode ? "dark" : "light")
        ),
        [
        prefersDarkMode ? "dark" : "light"
        ]
    );
    
    return (
        <Box overflow="hidden" component="div" sx={ { 
            backgroundSize: 'cover', 
            backgroundPosition: 'left', 
            display: "block"
        } }> 
            <Container sx={ { 
                zIndex: "200",
                display: "block"
            } }>
                <Box sx={ {
                    paddingTop: "30px"
                } }>
                    <Typography variant="h2" gutterBottom>{ title }</Typography>
                </Box>
                { children }
            </Container>
        </Box>
    );
}
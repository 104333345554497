import React, { ReactNode, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Box, Container, createTheme, Divider, Grid, Popover, Typography, useMediaQuery } from '@mui/material';
import { getDesignTokens } from 'AppGlobals';

type MarkerProperties = {
    lat: Number,
    lng: Number,
    text: string
}

const Marker:React.FC<MarkerProperties> = ({ lat, lng, text }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
      getDesignTokens(
        prefersDarkMode ? "dark" : "light")
      ),
      [
        prefersDarkMode ? "dark" : "light"
      ]
    );
    
    return (
        
        <Box sx={{ 
            height: "60px",
            width: "60px",
            background: theme.palette.background.default,
            display: "flex",
            borderRadius: "10%",
            alignItems: "center",
            position: "absolute"
         }}>
            <img width="48" src="/logo256.png" alt="The MADE"/>
        </Box>
        
    );
}

type MapWidgetProperties = {
    data: {
        directions: {
            google_maps: string;
            address: string;
            city: string;
            state: string;
            zip: string;
            parking: string;
            description: string;
        },
        hours: string
    },
    children?: ReactNode | undefined
}
  
export const MapWidget:React.FC<MapWidgetProperties> = ({ data, children }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');


    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hours, setHours] = useState([]);

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Cache-Control', 'no-cache');
    requestHeaders.set('Pragma', 'no-cache');
    requestHeaders.set('Expires', '0');

    useEffect(() => {
        fetch(data["hours"], { 'headers': requestHeaders })
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setHours(result);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
    }, [])
    

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
      getDesignTokens(
        prefersDarkMode ? "dark" : "light")
      ),
      [
        prefersDarkMode ? "dark" : "light"
      ]
    );


    var renderedHours = hours["hours"];
    console.log(renderedHours);

    // 37.802006074530965, -122.27429286956638
    return (
        <Container>
            <Box sx={ { 
                marginTop: "45px",
                marginBottom: "45px",
                background: theme.palette.secondary.main,
                borderRadius: "10px" 
            }} >
                <Grid container spacing={2} padding={2}>
                    <Grid item xs={12} md={5} lg={6}>
                        <Box sx={{ height: "400px", width: '100%' }}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.4900013775296!2d-122.27654458399665!3d37.80199017975444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f80b72e9e18cd%3A0x24dc95fa258648fc!2s921%20Washington%20St%2C%20Oakland%2C%20CA%2094607!5e0!3m2!1sen!2sus!4v1677469580645!5m2!1sen!2sus" width="100%" height="100%" allowFullScreen={ true } loading="lazy" referrerPolicy={ 'no-referrer-when-downgrade' } ></iframe>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <Typography variant="h5">Address</Typography>
                        <Divider></Divider>
                        { data.directions.address }<br />
                        { data.directions.city }, { data.directions.state } {data.directions.zip}
                        <br />
                        
                        <br />
                        <Typography variant="h5">Parking</Typography>
                        <Divider></Divider>
                        { data.directions.parking }
                        
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="h5">Hours</Typography>
                        <Divider></Divider>
                        <Grid container spacing={1}>
                        {
                            renderedHours ? 
                            renderedHours.map((hour) => {
                                return (
                                    <React.Fragment key={hour.day}>
                                        <Grid item xs={6} key={ hour.day}>
                                            { hour.day}
                                        </Grid>
                                        <Grid item xs={6} key={ hour.day + hour.start_time }>
                                            { hour.start_time } to { hour.end_time }
                                        </Grid>
                                    </React.Fragment>
                                )
                            }) :
                            <></>
                        }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

var _theme = createTheme({
    typography: {
        fontFamily: [
            'Inter',
        ].join(',')
    }
});

_theme = responsiveFontSizes(_theme);

export const theme = _theme;
  
import React, { useEffect, useState } from 'react';
import { Router, Route, Switch, Redirect, useParams } from 'react-router-dom';
import './App.css';

import PathError from './pages/PathError';
import WidgetGenerator from './widgets/WidgetGenerator';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getDesignTokens } from 'AppGlobals';
import { FooterWidget } from 'layout/Footer';
import { TopMenu } from 'layout/TopMenu';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

type PageProperties = {
  data: string;
};

/**
 * Get the title out of the json data, if it's there.
 * @param items the json object
 * @returns title as a string.
 */
function getTitle(items: any) {
    const idx = items["widgets"]?.findIndex(item => item["widget"] === "header");

    if (idx !== undefined && idx !== -1) {
      const title = items["widgets"][idx]["data"]["title"];
      return title;
    }

    return "";
}

export const Page: React.FC<PageProperties> = ({ data }) => {
  const params = useParams();
  const [items, setItems] = useState<any[]>([]);
  const requestHeaders: HeadersInit = new Headers({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(data, { headers: requestHeaders });
        const json = await res.json();
        setItems(json);
      } catch (error) {
        console.log("Failed to fetch data:", error);
      }
    }

    fetchData();
  }, [data]);

  useEffect(() => {
    const title = getTitle(items);

    if (title) {
      // Set the page title so Google can
      // extract a site link out of the page.
      // https://developers.google.com/search/docs/appearance/sitelinks?topic=8523&visit_id=638549492056541815-2429966036&rd=1
      document.title = `Museum of Art and Digital Entertainment: ${title}`;
    } else {
      document.title = "Museum of Art and Digital Entertainment";
    }
  }, [items]);


  let i = 0;
  const pageElements = items["widgets"]
    ? items["widgets"].map((pageItem: any) => {
        params["key"] = `page_${i++}`;
        const widget = WidgetGenerator(pageItem.widget)(pageItem.data, params);
        return widget;
      })
    : null;

  return <>{pageElements}</>;
};

export const Pages: React.FC = () => {
  const [error, setError] = useState<null | Error>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [pages, setPages] = useState<any[]>([]);
  const [prefix, setPrefix] = useState("");
  const requestHeaders: HeadersInit = new Headers({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch("/navigation/pages.json", { headers: requestHeaders });
        const json = await res.json();
        console.log("Fetched pages.json:", json);
        setIsLoaded(true);
        const lang = navigator.language.split("-")[0];
        const prefixes = json["dataPrefix"];
        const prefix = prefixes[lang] || prefixes["en"];
        setPages(json["pages"]);
        setPrefix(prefix);
      } catch (error) {
        setIsLoaded(true);
        setError(error as Error);
        console.error("Failed to fetch pages.json:", error);
      }
    }

    fetchData();
  }, []);

  if (error) {
    return <div>Error loading pages: {error.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (pages.length === 0) {
    return <div>No pages available</div>;
  }

  return (
    <Switch>
      {pages.map((pageItem, index) => (
        <Route key={index} exact path={pageItem.url}>
          {!pageItem.solo && <TopMenu />}
          {!pageItem.solo && <div className="pushTop"></div>}
          <Page data={`${prefix}${pageItem.dataUrl}`} />
          {!pageItem.solo && <FooterWidget />}
        </Route>
      ))}
      <Route key={pages.length} path="*">
        <PathError />
      </Route>
    </Switch>
  );
};

export const App: React.FC = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () => createTheme(getDesignTokens(prefersDarkMode ? "dark" : "light")),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <Switch>
          <Redirect from="/index.html" to="/" />
          <Redirect from="/es/index.html" to="/es" />
          <Pages />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;

import React, { ReactNode } from 'react';
import Container from '@mui/material/Container'
import { Box } from '@mui/material';
import { Sectional } from './Sectional';

export type SplashData = {
    title: React.ReactElement,
    content: React.ReactElement,
    background: string,
    color: string,
    align: string,
    image?: React.ReactElement
}

type SplashProperties = {
    bg?: string,
    color?: string,
    align?: string,
    image?: string,
    image_width?: string,
    children?: ReactNode | undefined;
}

type MiniSplashProperties = {
    title: string,
    bg: string,
    color: string
}

type ColumnListProperties = {
    title: string,
    columnCount: number,
    items: React.ReactElement[]
}

type ImageSplashProperties = {
    url: string,
    x?: string,
    y?: string,
    height?: string,
    children?: ReactNode | undefined
}

type SplashTitleProps = {
    align?: string,
    children: ReactNode | undefined
}


export const ColumnList: React.FC<ColumnListProperties> = ({title, columnCount, items}) => {
    const columns:JSX.Element[] = [];
    const interval = items.length / columnCount;

    for (let _i = 0; _i < columnCount; _i++) {
        columns.push(
            //  lg={12 / columnCount}
            <div key={_i}>{items.slice(_i * interval, (_i + 1) * interval)}</div>
        );
    }

    columns.sort((a, b) => {
        return (a.type.length > b.type.length) ? 1 : -1
    });

    return (
        <Sectional title={ title }>
            { columns }
        </Sectional>
    );
}


interface AlignmentMap {
    [alignment: string]: React.ReactElement;
}

export const ImageSplash: React.FC<ImageSplashProperties> = ({ url, x = "0px", y = "0px", height = "500px" }) => {
    return (
        <>
            <img width="100%" src={ url } alt={ url }/>
        </>
    );
}

export const SplashColumnChild:  React.FC<{ children: ReactNode | undefined}> = ({ children = <></>}) => {
    return (
        <>
            {/* <Col lg={{span: 6, offset: 0}}>
                { children }
            </Col> */}
        </>
    )
}

export const SplashColumn: React.FC<{ children: ReactNode | undefined}> = ({ children }) => {

    return (
        <Sectional>
            {/* <Row>
                { children }
            </Row> */}
        </Sectional>
    );
}

export const Splash: React.FC<SplashProperties> = (
        {bg = "light_cool", 
        color = "dark_cool_text", 
        align = "full", 
        children = <></>, 
        image = null, 
        image_width = "100%"
    }) => {

    const alignmentMap:AlignmentMap = {
        "left": (
            <>
                {children}
                { 
                    image == null ? <></> :
                    <img width={ image_width } src={ image } alt=""/>
                }
            </>
        ),
        "center": (
            <>
                {children}
            </>
        ),
        "right": (
            <>
                { 
                    image == null ?
                    <></> : <img width={ image_width } src={ image } alt=""/>
                }
                    { children }
            </>
        ),
        "full": (
            <>
                {children}
            </>
        )
    };

    var internals = alignmentMap[align];
    return (
        <Box>
            { internals }
        </Box>
    );
}

export const SplashTitle: React.FC<SplashTitleProps> = ({ align, children }) => {
    return (
        <h4 className={ align == null ? "title" : "title " + align }>
            {children}
        </h4>
    )
}

export const SplashContent: React.FC<{ children: ReactNode | undefined}> = ({ children }) => {
    return (
        <>
            { children }
        </>
    )
}

export default Splash;

import { Box, Grid, createTheme, useMediaQuery } from "@mui/material";
import { getDesignTokens } from "AppGlobals";
import { QuoteSplash, QuoteSplashSubtitle } from "layout/QuoteSplash";
import { Sectional } from "layout/Sectional";
import Splash from "layout/Splash";
import React, {useRef, useLayoutEffect, useState} from "react";
import { Cell, Label, Pie, PieChart,
         CartesianGrid, LineChart, Line, XAxis, YAxis, Tooltip, BarChart, Bar} from "recharts";
import { YoutubeWidget } from "./Youtube";

type MetricDataBlob = {
    name: string,
    value: number
}

type TimeDataPoint = {
    Month: string,
    Visits: number,
    TotalVisits: number
}

type MetricDataSet = {
    name: string,
    unit?: string,
    type?: string,
    cumulative?: boolean,
    analysis: string,
    plotdata: MetricDataBlob[] | TimeDataPoint[],
    dataKey: string
}

type MetricsProperties = {
    title: string,
    image?: string,
    description?: string,
    fields: MetricDataSet[]
}

type ChartSizeProps = {
    contentRef: React.RefObject<HTMLHeadingElement>,
    chartWidth: number
}

let renderLabel = (entry) => {
    return `${entry.name}: ${entry.value}`
}

const ChartSizer = (WrappedChart: React.FC<MetricDataSet & ChartSizeProps>): React.FC<MetricDataSet> => {
    const SizedChart: React.FC<MetricDataSet> = (props: MetricDataSet) => {
        const DEFAULT_CHART_WIDTH = 500;

        const containerContentRef = useRef<HTMLHeadingElement>(null);
        const [containerContentWidth, setContainerContentWidth] = useState(DEFAULT_CHART_WIDTH);

        useLayoutEffect(() => {
            function setWidth() {
                if(containerContentRef && containerContentRef.current) {
                    setContainerContentWidth(containerContentRef.current.getBoundingClientRect().width);
                }
            }
            window.addEventListener('resize', setWidth);
            
            setWidth();
            
            return () => window.removeEventListener('resize', setWidth);
        }, []);


        return <WrappedChart {...props} contentRef={containerContentRef} chartWidth={containerContentWidth} />
    }

    return SizedChart;
}

export const PieChartComponent: React.FC<MetricDataSet> = ChartSizer(
    ({ name, unit, analysis, plotdata, contentRef, chartWidth, dataKey }: MetricDataSet & ChartSizeProps) => {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
        getDesignTokens(
            prefersDarkMode ? "dark" : "light")
        ),
        [
        prefersDarkMode ? "dark" : "light"
        ]
    );
    
    var COLORS:string[] = new Array<string>();
    plotdata.forEach(field => {
        var r1 = Math.floor(Math.random() * 0xFF);
        var r2 = Math.floor(Math.random() * 0xFF);
        var r3 = Math.floor(Math.random() * 0xFF);
        var color = Math.pow(2, 16) * r1 +
            Math.pow(2, 8) * r2 +
            r3;

        COLORS.push(
            color.toString(16).padStart(6, "0")
        );
    });

    return (
        <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box sx={ {paddingRight: "20px"} } >
                <h2 ref={contentRef}>{ name }</h2>
                <PieChart width={chartWidth} height={500}>
                    <Label value="Fni"></Label>
                    <Tooltip wrapperStyle={{backgroundColor: theme.palette.background.default}} itemStyle={{ color: theme.palette.secondary.dark }} labelStyle={{display: "none"}} />
                    <Pie label={renderLabel} data={plotdata} dataKey="value" nameKey={ dataKey } cx="50%" cy="50%" outerRadius={150} innerRadius={100} fill="#8884d8">
                        {
                            plotdata.map((entry, index) => <Cell fill={ `#${COLORS[index]}` }/>)
                        }
                    </Pie>
                </PieChart>
                
                <p dangerouslySetInnerHTML={ { __html:  analysis } }>
                </p>
            </Box>
        </Grid>
    );
});

export const BarChartComponent: React.FC<MetricDataSet> = ChartSizer(({ name, unit, analysis, plotdata, contentRef, chartWidth, dataKey }) => {
    var COLORS:string[] = new Array<string>();
    plotdata.forEach(field => {
        var r1 = Math.floor(Math.random() * 0xFF);
        var r2 = Math.floor(Math.random() * 0xFF);
        var r3 = Math.floor(Math.random() * 0xFF);
        var color = Math.pow(2, 16) * r1 +
            Math.pow(2, 8) * r2 +
            r3;

        COLORS.push(
            color.toString(16).padStart(6, "0")
        );
    });

    return (
        <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box sx={ {paddingRight: "20px"} }>
                <h2 ref={contentRef}>{ name }</h2>
                <BarChart width={chartWidth} height={500} data={plotdata}>
                    <Label value="Fni"></Label>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={ dataKey } />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value" name="No. of Students">
                        {
                            plotdata.map((entry, index) => <Cell fill={ `#${COLORS[index]}` }/>)
                        }
                    </Bar>
                </BarChart>
                <p>
                    { analysis }
                </p>
            </Box>
        </Grid>
    );
});

export const QualitativeComponent: React.FC<MetricDataSet> = ({ name, unit, analysis, plotdata, dataKey }) => {
    var COLORS:string[] = new Array<string>();
    plotdata.forEach(field => {
        var r1 = Math.floor(Math.random() * 0xFF);
        var r2 = Math.floor(Math.random() * 0xFF);
        var r3 = Math.floor(Math.random() * 0xFF);
        var color = Math.pow(2, 16) * r1 +
            Math.pow(2, 8) * r2 +
            r3;

        COLORS.push(
            color.toString(16).padStart(6, "0")
        );
    });

    console.log(plotdata[0]["Analysis"]);

    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={ {paddingRight: "20px"} }>
                    <h2>{ name }</h2>
                    <p>{ analysis }</p>
                </Box>
            </Grid>
            {
                plotdata.map((entry, index) => (
                    <React.Fragment key={ index }>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box sx={ {paddingRight: "20px"} }> 
                                { 
                                entry["Type"] == "image" ?
                                    <img width="100%" src={ String(entry["Image"]) } /> :
                                    <YoutubeWidget title="" url={ entry["Media"] }  component={ false }></YoutubeWidget>

                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            { String(entry["Analysis"]) }
                            <ul>
                            {
                                entry["Bullets"].map((item, idx) => {
                                    return (
                                        <li key={ idx }>
                                            { item }
                                        </li>
                                    )
                                })
                            }
                            </ul>
                        </Grid>
                    </React.Fragment>
                ))
            }
        </>
    );
}

export const LineChartComponent: React.FC<MetricDataSet> = ChartSizer((
    { name, unit, plotdata, analysis, cumulative, contentRef, chartWidth, dataKey }) => {
    if (cumulative) {
        var total_key = "Total " + unit
        const cumsum = (sum => value => sum += value)(0);
        plotdata.forEach( thing => thing[total_key] = cumsum(thing[unit as string]) )
        unit = total_key
    }
    return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box sx={{ paddingRight: "20px" }}>
        <h2 ref={contentRef}>{ name }</h2>
            <LineChart width={chartWidth} height={250} data={plotdata}
                margin={{ top: 5, right: 30, left: 20, bottom: 0 }}>
                <CartesianGrid strokeDasharray="4 4" />
                <XAxis dataKey={ dataKey } />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey={ unit } stroke="#8884d8" strokeWidth={3}/>
            </LineChart>
            <p>
                {analysis}
            </p>
        </Box>
    </Grid>);
});

export const MultiMetric: React.FC<MetricDataSet> = ({ name, type, unit, analysis, plotdata, cumulative, dataKey = "Month" }) => {
    if (type == "line") {
        return (
            <LineChartComponent
                name={name}
                unit={unit}
                plotdata={plotdata}
                analysis={analysis}
                cumulative={cumulative}
                dataKey={ dataKey } />
        );
    }
    if (type == "bar") {
        return (
            <BarChartComponent
                name={name}
                unit={unit}
                plotdata={plotdata}
                analysis={analysis}
                cumulative={cumulative}
                dataKey={ dataKey } />
        );
    }
    if (type == "qualitative") {
        return (
            <QualitativeComponent
                name={name}
                unit={unit}
                plotdata={plotdata}
                analysis={analysis}
                cumulative={cumulative}
                dataKey={ dataKey } />
        );
    }
    return (
        <PieChartComponent
            name={name}
            unit={unit}
            analysis={analysis}
            plotdata={plotdata}
            dataKey={ dataKey } />
    );
}

export const MetricsWidget: React.FC<MetricsProperties> = ({ title, image, description, fields }) => {
    
    const img = typeof image === "string" ? <img style={ { objectFit: "cover"} } width="100%" height="400px" src={ image as string } /> : <></>;

    const desc = typeof description === "string" ? <h3>{ description as string }</h3> : <></>;

    return (
        <>
            <Sectional title={ title }>
                { img }
                { desc }
                <Grid container>
                    {
                        fields.map((dataSet) => {
                            return <>
                                <MultiMetric 
                                    name={ dataSet.name }
                                    type={ dataSet.type}
                                    unit={ dataSet.unit }
                                    cumulative={ dataSet.cumulative}
                                    analysis={ dataSet.analysis }
                                    plotdata={ dataSet.plotdata }
                                    dataKey={ dataSet.dataKey } />
                            </>
                        })
                    }
                </Grid>
            </Sectional>
        </>
    );
}

import { ExhibitExperience } from '../ExhibitRenderer';
import * as THREE from 'three';
import { ExhibitStorage, ExhibitStoragePrototype } from './ExhibitStorage';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry.js';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { Line } from 'three';
import React from 'react';

type LineMap = {
    index: Number
    exists: Boolean
}

type CompletedFace = {
    point1: THREE.Vector3
    point2: THREE.Vector3
    point3: THREE.Vector3
}

export class GeometryExperience implements ExhibitExperience {
    canvas: HTMLCanvasElement
    renderer: THREE.WebGLRenderer
    scene: THREE.Scene
    camera: THREE.PerspectiveCamera
    pointGroup: THREE.Group
    lineGroup: THREE.Group
    faceGroup: THREE.Group
    faces: Array<CompletedFace> = []
    pointGeo: THREE.IcosahedronGeometry
    pointMaterial: THREE.MeshStandardMaterial
    meshMaterial: THREE.MeshLambertMaterial
    meshMaterial2: THREE.MeshLambertMaterial
    raycaster: THREE.Raycaster
    mouse: THREE.Vector2
    colorBlue: THREE.Color
    colorWhite: THREE.Color
    colorGrey: THREE.Color
    colorGreen: THREE.Color
    lineMaterial: LineMaterial
    objects: Array<THREE.Mesh>;
    points: Array<number>;
    colors: Array<number>;
    trianglePoints: Array<THREE.Vector3>;
    controls: OrbitControls;
    mappings: Map<number, LineMap>;
    solvedCallback: React.Dispatch<React.SetStateAction<boolean>> | undefined
    
    constructor (canvas: HTMLCanvasElement) {
        this.canvas = canvas;
        this.renderer = new THREE.WebGLRenderer( { antialias: true, canvas: this.canvas } );
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 1000 );
        this.controls = new OrbitControls( this.camera, this.canvas);

        this.pointGroup = new THREE.Group();
        this.lineGroup = new THREE.Group();
        this.faceGroup = new THREE.Group();
        this.pointGeo = new THREE.IcosahedronGeometry( 0.4, 3 );        
        this.meshMaterial = new THREE.MeshLambertMaterial( {
            color: 0xffffff,
            opacity: 0.1,
            transparent: true
        } );				
        this.meshMaterial2 = new THREE.MeshLambertMaterial( {
            color: 0xff0000,
            opacity: 0.1,
            transparent: true
        } );

        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2( 1, 1 );
        this.colorBlue = new THREE.Color(0x0000ff);
        this.colorWhite = new THREE.Color(0xffffff);
        this.colorGrey = new THREE.Color(0xeeeeee);
        this.colorGreen = new THREE.Color(0x00ff00);
        this.lineMaterial = new LineMaterial( {
            color: 0x111111,
            linewidth: 0.005,
            vertexColors: true,
            dashed: false,
            alphaToCoverage: true,
        } );

        this.objects = [];
        this.points = [];
        this.colors = [];
        this.trianglePoints = [];
        this.mappings = new Map<number, LineMap>();
        

        this.pointMaterial = new THREE.MeshStandardMaterial( { color: this.colorGrey } );

        this.init();
        
        this.animate();
    }

    init() {

        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color( 0xffffff );

        
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        // document.body.appendChild( renderer.domElement );

        // camera
        this.camera.position.set( 4, 8, 20 );
        this.scene.add( this.camera );

        // controls
        this.controls.minDistance = 20;
        this.controls.maxDistance = 50;
        // controls.maxPolarAngle = Math.PI / 2;
        this.controls.enableDamping = true;
        this.controls.enableZoom = false;
        this.controls.enablePan = false;
        this.controls.dampingFactor = 0.1;
        this.controls.minAzimuthAngle = 0
        this.controls.maxAzimuthAngle = .1
        this.controls.update()

        // ambient light
        this.scene.add( new THREE.AmbientLight( 0x222222 ) );

        // point light

        const light = new THREE.PointLight( 0xffffff, 0.8 );
        this.camera.add( light );

        // textures
        const loader = new THREE.TextureLoader();
        const texture = loader.load( 'textures/sprites/disc.png' );

        this.scene.add( this.pointGroup );
        this.scene.add( this.lineGroup );
        this.scene.add( this.faceGroup );

        // points

        let cylinderGeometry = new THREE.CylinderGeometry( 0, 4, 5, 3 );

        // if normal and uv attributes are not removed, mergeVertices() can't consolidate indentical vertices with different normal/uv data

        cylinderGeometry.deleteAttribute( 'normal' );
        cylinderGeometry.deleteAttribute( 'uv' );

        cylinderGeometry = BufferGeometryUtils.mergeVertices( cylinderGeometry ) as THREE.CylinderGeometry;

        const vertices: Array<THREE.Vector3> = [];
        const positionAttribute = cylinderGeometry.getAttribute( 'position' );

        for ( let i = 0; i < positionAttribute.count-1; i ++ ) {

            const vertex:THREE.Vector3 = new THREE.Vector3();
            vertex.fromBufferAttribute( positionAttribute, i );
            vertices.push( vertex );

            const pointMesh:THREE.Mesh = new THREE.Mesh( this.pointGeo, this.pointMaterial );
            pointMesh.position.set( vertex.x, vertex.y, vertex.z );
            this.pointGroup.add( pointMesh );
            this.objects.push( pointMesh );
            this.mappings[pointMesh.id] = {index: i, exists: false};
            console.log(i)
        }
        console.log(positionAttribute)

        // convex hull

        const meshGeometry = new ConvexGeometry( vertices );

        const mesh1 = new THREE.Mesh( meshGeometry, this.meshMaterial );

        // mesh1.material.side = THREE.BackSide; // back faces
        mesh1.renderOrder = 0;
        this.scene.add( mesh1 );

        window.addEventListener( 'resize', (event) => this.onWindowResize() );
        document.addEventListener( 'mousemove', (event) => this.onMouseMove(event) );
        document.addEventListener( 'pointerdown', (event) => this.onPointerDown(event) );

    }

    onWindowResize() {

        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize( window.innerWidth, window.innerHeight );

    }


    onMouseMove( event ) {
        event.preventDefault();
        this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

        this.raycaster.setFromCamera( this.mouse, this.camera );

        const intersects = this.raycaster.intersectObjects( this.objects, false );

        for (let i = 0; i < this.objects.length; i++) {
            this.objects[i].scale.set(1,1,1);
        }

        if ( intersects.length > 0 ) {
            const intersect = intersects[ 0 ];
            
            const pointObject = intersect.object;
            pointObject.scale.set(1.5,1.5,1.5);
        }

    }

    processLine(pointObject: THREE.Mesh) {
        this.mappings[pointObject.id].exists = true;

        this.points.push(pointObject.position.x)
        this.points.push(pointObject.position.y)
        this.points.push(pointObject.position.z)
        
        this.colors.push(this.colorBlue.r)
        this.colors.push(this.colorBlue.g)
        this.colors.push(this.colorBlue.b)

        const geometry = new LineGeometry();
        geometry.setPositions( this.points );
        geometry.setColors( this.colors )
        const line = new Line2(
            geometry,
            this.lineMaterial
        );
        this.lineGroup.add( line );
    }

    faceExists(newFace: CompletedFace):boolean {
        for (let i = 0; i < this.faces.length; i++) {
            let existingFace = this.faces[i];
            let match1 = existingFace.point1 === newFace.point1 || 
                existingFace.point1 === newFace.point2 ||
                existingFace.point1 === newFace.point3
            let match2 = existingFace.point2 === newFace.point1 || 
                existingFace.point2 === newFace.point2 ||
                existingFace.point2 === newFace.point3
            let match3 = existingFace.point3 === newFace.point1 || 
                existingFace.point3 === newFace.point2 ||
                existingFace.point3 === newFace.point3
            if (match1 && match2 && match3) {
                return true
            }
        }
        return false
    }

    processFace(completedFace: CompletedFace) {
        var mat = new THREE.MeshBasicMaterial( {
            color: 0xff0000,
            transparent: true,
            opacity: 0.5
        } );

        mat.side = THREE.DoubleSide;
        console.log(this.faces)
        if (!this.faceExists(completedFace)) {
            for (let i = 0; i < this.pointGroup.children.length; i++) {
                let child = (this.pointGroup.children[i] as THREE.Mesh);
                (child.material as THREE.MeshStandardMaterial).color = this.colorWhite

            }
            this.faces.push(completedFace)
            let tri = new ConvexGeometry( this.trianglePoints );
            var m = new THREE.Mesh( tri, mat );
            this.faceGroup.add( m );
        }   
    }

    clicked: Array<number> = []
    processValidPoint(pointObject: THREE.Mesh):boolean {
        const idIdx = this.clicked.indexOf(pointObject.id)
        console.log(this.clicked)
        if (this.clicked.length < 3) {
            if (idIdx === -1) {
                this.clicked.push(pointObject.id)
                this.trianglePoints.push(pointObject.position);

                pointObject.material = (pointObject.material as THREE.Material).clone();
                (pointObject.material as THREE.MeshStandardMaterial).color = this.colorWhite;
                (pointObject.material as THREE.MeshStandardMaterial).color = this.colorGreen;

                return true
            }
        } else {
            if (idIdx === 0) {

                this.trianglePoints.push(pointObject.position);
                this.processFace({
                    point1: this.trianglePoints[0],
                    point2: this.trianglePoints[1],
                    point3: this.trianglePoints[2]
                })
                this.clicked = []
                this.trianglePoints = []
                return true
            }
        }
        return false
    }

    onPointerDown( event ) {

        event.preventDefault();

        this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

        this.raycaster.setFromCamera( this.mouse, this.camera );

        const intersects = this.raycaster.intersectObjects( this.objects, false );

        if ( intersects.length > 0 ) {

            const intersect = intersects[ 0 ];
            const pointObject:THREE.Mesh = intersect.object as THREE.Mesh;

            if (this.processValidPoint(pointObject)) {
                this.processLine(pointObject)
            }
            if (this.faces.length == 4) {
                this.exhibitStorage.exhibit_one = true
                if (this.solvedCallback != undefined) {
                    this.solvedCallback(true)
                }
            }
        }
    }

    

    animate() {
        requestAnimationFrame(() => this.animate());
        
        this.controls.update()
        this.render();

    }

    render() {
        this.renderer.render( this.scene, this.camera );

    }
    exhibitStorage: ExhibitStoragePrototype = ExhibitStorage
}
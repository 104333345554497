import { Box, createTheme, Grid, useMediaQuery } from "@mui/material";
import { getDesignTokens } from "AppGlobals";
import { ImageSectional } from "layout/ImageSectional";
import React from "react";

export const PlayWidget: React.FC = () => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
        getDesignTokens(
            prefersDarkMode ? "dark" : "light")
        ),
        [
            prefersDarkMode ? "dark" : "light"
        ]
    );
  
    return (
        <ImageSectional title="">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box>
                        <h3>This</h3>
                    </Box>
                </Grid>
            </Grid>
        </ImageSectional>
    );
}

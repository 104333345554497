function invariant(condition: any, message: string): asserts condition {
    if (!condition) {
        throw new Error(message);
    }
}

class Vec2 {
    static distance(a: Vec2, b: Vec2) {
        return Math.hypot(a.x - b.x, a.y - b.y);
    }
    constructor(public x = 0, public y = 0) {}
    copy(v: Vec2) {
        this.x = v.x;
        this.y = v.y;
        return this;
    }
    add(v: Vec2) {
        this.x += v.x;
        this.y += v.y;
        return this;
    }
}

class Vec3 {
    constructor(public x = 0, public y = 0, public z = 0) {}
    copy(v: Vec3) {
        this.x = v.x;
        this.y = v.y;
        this.z = v.z;
    }
}

class Coroutine {
    private _scheduled = false;
    private _frameHandle = 0;
    constructor(readonly fn: (timestamp: number) => void, readonly object: any = null) {}

    schedule() {
        if (!this._scheduled) {
            this._scheduled = true;
            this._frameHandle = window.requestAnimationFrame(this._handleFrame);
        }
    }

    private _handleFrame = (timestamp: number) => {
        this._scheduled = false;
        this.fn.call(this.object, timestamp);
    }

    cancel() {
        this._scheduled = false;
        window.cancelAnimationFrame(this._frameHandle);
    }
}

const debugEl = document.getElementById("debug")!;
function debug(...items: any[]) {
    const text = items.map(item => JSON.stringify(item)).join(" ");
    console.debug(text);
    debugEl.textContent = text;
}

export class ZoomPanPinchControls {
    private pointerPositions = [] as Vec2[];
    private processEventsCoroutine = new Coroutine(this.processEvents, this);
    private minTouchDistance = 0;
    private cameraStartPosition = new Vec3();
    private averagePointerStartPosition = new Vec2();
    private averagePointerPosition = new Vec2();
    private panSpeed = 0.0015;
    private lastPointerCount = 0;
    private gestureStartTime = 0;
    private wheelSpeed = 0.9;
    private touchPanResolveTime = 200;

    constructor(readonly element: HTMLElement, readonly cameraPosition: Vec3) {
        invariant(this.isElement(element), "element must be an HTMLElement");
    }

    start() {
        const {element} = this;

        // TODO derive two polymorphic classes for each case and return the appropriate instance in a factory function
        if (window.PointerEvent) {
            // Add Pointer Event Listener
            element.addEventListener('pointerdown', this.handleGestureStart, true);
            element.addEventListener('pointermove', this.handleGestureMove, true);
            element.addEventListener('pointerup', this.handleGestureEnd, true);
            element.addEventListener('pointercancel', this.handleGestureEnd, true);
        } else {
            // Add Touch Listener
            element.addEventListener('touchstart', this.handleGestureStart, true);
            element.addEventListener('touchmove', this.handleGestureMove, true);
            element.addEventListener('touchend', this.handleGestureEnd, true);
            element.addEventListener('touchcancel', this.handleGestureEnd, true);

            // Add Mouse Listener
            element.addEventListener('mousedown', this.handleGestureStart, true);
        }

        window.addEventListener('wheel', this.onMouseWheel, { passive: false });
    }

    stop() {
        const {element} = this;

        if (window.PointerEvent) {
            // Remove Pointer Event Listener
            element.removeEventListener('pointerdown', this.handleGestureStart, true);
            element.removeEventListener('pointermove', this.handleGestureMove, true);
            element.removeEventListener('pointerup', this.handleGestureEnd, true);
            element.removeEventListener('pointercancel', this.handleGestureEnd, true);
        } else {
            // Remove Touch Listener
            element.removeEventListener('touchstart', this.handleGestureStart, true);
            element.removeEventListener('touchmove', this.handleGestureMove, true);
            element.removeEventListener('touchend', this.handleGestureEnd, true);
            element.removeEventListener('touchcancel', this.handleGestureEnd, true);

            // Remove Mouse Listener
            element.removeEventListener('mousedown', this.handleGestureStart, true);
        }
        window.removeEventListener('wheel', this.onMouseWheel);
    }

    isTouchEvent(evt: PointerEvent | TouchEvent | MouseEvent): evt is TouchEvent {
        return evt.type === "touchstart" || evt.type === "touchmove" || evt.type === "touchend";
    }

    isPointerEvent(evt: PointerEvent | TouchEvent | MouseEvent): evt is PointerEvent {
        return evt.type === "pointerdown" || evt.type === "pointermove" || evt.type === "pointerup";
    }

    isElement(element: EventTarget | null): element is HTMLElement {
        return element !== null && "nodeType" in element && element.nodeType === Node.ELEMENT_NODE;
    }

    onMouseWheel = ( evt: WheelEvent ) => {
        const {cameraPosition} = this;
        evt.preventDefault();
        if (evt.deltaY < 0) {
            cameraPosition.z *= this.wheelSpeed;
        } else {
            cameraPosition.z /= this.wheelSpeed;
        }
    }

    handleGestureStart = (evt: PointerEvent | TouchEvent | MouseEvent) => {
        const target = this.element;
        if(evt.target !== target) {
            // defensive sanity check
            return;
        }

        evt.preventDefault();

        const point = this.getGesturePointFromEvent(evt);

        // Add the move and end listeners
        if (window.PointerEvent) {
            invariant(this.isPointerEvent(evt), 'Expected a PointerEvent');
            target.setPointerCapture(evt.pointerId);
            this.pointerPositions[evt.pointerId] = point;
        } else {
            // Add Mouse Listeners
            document.addEventListener('mousemove', this.handleGestureMove, true);
            document.addEventListener('mouseup', this.handleGestureEnd, true);
            this.pointerPositions[0] = point;
        }

        this.cameraStartPosition.copy(this.cameraPosition);
        this.averagePointerStartPosition.copy(point);
        this.gestureStartTime = Date.now();
    }

    handleGestureEnd = (evt: PointerEvent | TouchEvent | MouseEvent) => {
        const target = this.element;
        if(evt.target !== target) {
            // defensive sanity check
            return;
        }

        evt.preventDefault();

        // Remove Event Listeners
        if (window.PointerEvent) {
            invariant(this.isPointerEvent(evt), 'Expected a PointerEvent');
            target.releasePointerCapture(evt.pointerId);

            delete this.pointerPositions[evt.pointerId];
        } else {
            // Remove Mouse Listeners
            document.removeEventListener('mousemove', this.handleGestureMove, true);
            document.removeEventListener('mouseup', this.handleGestureEnd, true);
            delete this.pointerPositions[0];
        }

        this.processEventsCoroutine.cancel();
        this.minTouchDistance = 0;
    }

    handleGestureMove = (evt: PointerEvent | TouchEvent | MouseEvent) => {
        evt.preventDefault();

        let point: Vec2;

        if (window.PointerEvent) {
            invariant(this.isPointerEvent(evt), 'Expected a PointerEvent');
            point = this.pointerPositions[evt.pointerId]
        } else {
            point = this.pointerPositions[0];
        }

        // TODO this should be an invariant, but it isn't. Why?
        // invariant(point, 'Expected a point');

        if(point) {
            this.getGesturePointFromEvent(evt, point)

            this.processEventsCoroutine.schedule();
        }

    }


    getGesturePointFromEvent(evt: PointerEvent | TouchEvent | MouseEvent, point = new Vec2()) {
        if (this.isTouchEvent(evt)) {
            // Prefer Touch Events
            point.x = evt.targetTouches[0].clientX;
            point.y = evt.targetTouches[0].clientY;
        } else {
            // Either Mouse event or Pointer Event
            point.x = evt.clientX;
            point.y = evt.clientY;
        }

        return point;
    }

    minScale = Infinity;
    maxScale = 0;

    processEvents() {
        const positions = Object.values(this.pointerPositions);
        if (positions.length > 1) {
            const dt = Date.now() - this.gestureStartTime;
            if(dt < this.touchPanResolveTime) {
                this.updateStartPosition(positions);
            } else {
                this.processPanEvents(positions);
            }
            this.processZoomEvents(positions);
        } else if(positions.length === 1) {
            this.processPanEvents(positions);
        }
        this.lastPointerCount = positions.length;
    }

    updateStartPosition(positions: Vec2[]) {
        const {averagePointerStartPosition} = this;
        const len = positions.length;

        averagePointerStartPosition.x = 0;
        averagePointerStartPosition.y = 0;
        for(const pos of positions) {
            averagePointerStartPosition.add(pos);
        }
        averagePointerStartPosition.x /= len
        averagePointerStartPosition.y /= len
    }

    processPanEvents(positions: Vec2[]) {
        // Move to the average of all pointers
        const {averagePointerPosition } = this;
        const {cameraPosition} = this;
        const len = positions.length;

        // prevent jumping when a finger is lifted
        if(len < this.lastPointerCount) {
            return;
        }

        averagePointerPosition.x = 0;
        averagePointerPosition.y = 0;
        for(const pos of positions) {
            averagePointerPosition.add(pos);
        }
        averagePointerPosition.x /= len
        averagePointerPosition.y /= len

        const deltaX = (averagePointerPosition.x - this.averagePointerStartPosition.x) * this.panSpeed * cameraPosition.z;
        const deltaY = (averagePointerPosition.y - this.averagePointerStartPosition.y) * this.panSpeed * cameraPosition.z;
        cameraPosition.x = this.cameraStartPosition.x - deltaX;
        cameraPosition.y = this.cameraStartPosition.y + deltaY;

    }

    processZoomEvents(positions: Vec2[]) {
        const [first, second] = positions;
        const distance = Vec2.distance(first, second);

        if (this.minTouchDistance !== 0) {
            const scale = distance / this.minTouchDistance;
            this.cameraPosition.z /= scale;
        }
        this.minTouchDistance = distance;
    }
}

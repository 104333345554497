import { Box, Button, Container, createTheme, Grid, useMediaQuery } from "@mui/material";
import { QuoteSplash, QuoteSplashSubtitle, QuoteSplashTitle } from "layout/QuoteSplash"
import React, { useState, useEffect, ReactNode, useMemo } from "react"
import { Canvas } from "renderer/ExhibitRenderer";
import { ScavengerHuntMapDelegate } from "./ScavengerHuntMapWidget";

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {MapPin, PinMapLayer} from "./MapLayer";
import { theme } from "layout/Themes";
import { getDesignTokens } from "AppGlobals";

enum Colors {
  Gold = '#faaf0b',
  GoldDark = '#c88c09',
  Eggshell = '#f2efdc',
  EggshellMedium = '#d9d1a3',
  EggshellDark = '#aea782',
}

// update these strings to match QR code url :exhibit_id targets
// /scavenger_hunt_:exhibit_id

enum ScavengerHuntQRCodes {
  zero = "04_15_acdqjpqlfm",
  one = "04_15_gqmdqyowzt",
  two = "04_15_xewfiqelod",
  reset = "reset",
  validate = "validate",
  map = "map"
}

type ScavengerHuntItem = {
  content: string,
  title: string,
  answer: string,
  link: string,
  image: string
};

type ScavengerHuntMap = {
  [key in ScavengerHuntQRCodes]: ScavengerHuntItem
}

var headline = createTheme({
  typography: {
    fontFamily: [
      'Inter',
    ].join(','),
    h1: {
      fontSize: '7rem',
      '@media (min-width:600px)': {
        fontSize: '5rem',
      },
      [theme.breakpoints.up('xs')]: {
        fontSize: '3.5rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '3.5rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '4rem',
      },
    }
  },
});

const scavengerHuntSequence: ScavengerHuntMap = {
  [ScavengerHuntQRCodes.zero]: {
    content: "In the survival horror game, Resident Evil 4, what is the name of the burlap-masked enemy who wields a chainsaw?",
    title: "Challenge One",
    answer: "dr. salvador",
    link: "",
    image: ""
  },
  [ScavengerHuntQRCodes.one]: {
    content: "What game series has you hunting ghosts with a camera?",
    title: "Challenge Two",
    answer: "fatal frame",
    link: "",
    image: "",
  },
  [ScavengerHuntQRCodes.two]: {
    content: "The Last of Us franchise features infected enemies that cannot see the player and only react to sound. What are they called?",
    title: "Challenge Three",
    answer: "clickers",
    link: "",
    image: "",
  },
  [ScavengerHuntQRCodes.reset]: {
    content: "Resetting",
    title: "",
    answer: "",
    link: "",
    image: ""
  },
  [ScavengerHuntQRCodes.validate]: {
    content: "Scavenger hunt status:",
    title: "MADE Scavenger Hunt Validation",
    answer: "",
    link: "",
    image: ""
  },
  [ScavengerHuntQRCodes.map]: {
    content: "Scavenger Hunt Map",
    title: "MADE Scavenger Hunt Map",
    answer: "",
    link: "",
    image: ""
  },
};

const ScavengerHuntInvalidLanding = () => {
  return (
    <>
      Invalid URL
    </>
  )
}

const ScavengerHuntReset = () => { 

  return (
    <>
      <QuoteSplash smallImage="" bigImage="">
        <QuoteSplashTitle>Reset Exhibits</QuoteSplashTitle>
        <QuoteSplashSubtitle>
          All exhibit states reset.
        </QuoteSplashSubtitle>
      </QuoteSplash>
    </>
  )
}


const leftItemStyles = {
  borderTopLeftRadius: "1rem",
  borderBottomLeftRadius: "1rem",
}
const rightItemStyles = {
  borderTopRightRadius: "1rem",
  borderBottomRightRadius: "1rem",
}
const centerItemStyles = {
}
const ProgressBar = ({completed}: {completed: Array<boolean>}) => {
  const total = completed.length;
  const completeCount = completed.filter((item) => item).length;
  const flexBasis = `calc(${100 / total}% - ${total - 1}rem/${total})`;
  const flex = `0 1 ${flexBasis}`;
  const progressItems = completed.map((_, index, items) => {
    const completedItem = completed[index];
    const itemStyles = index === 0 ? leftItemStyles : index === items.length - 1 ? rightItemStyles : centerItemStyles;
    const borderColor = completedItem ? Colors.GoldDark : Colors.EggshellDark;
    const backgroundColor = completedItem ? Colors.Gold : Colors.EggshellMedium;

    return <div key={index} style={ { flex, border: `0.1rem solid ${borderColor}`, backgroundColor, ...itemStyles} }></div>
  });
  return (
    <>
      <div style={ { display: "fixed", width: "100%", height: "100%"}}>
        <div style={ { width: "100%", height: "1rem", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between"} }>
          {progressItems}
        </div>
      </div>
      <p style={{color: "black"}}><b>Progress:</b> {completeCount} / {total} Complete</p>
    </>
  )
}

/**
* MapChrome
* A react component that wraps the map canvas and renders a progress bar given an array of booleans indicating the completed puzzles
* @param completed - an array of booleans indicating the completed puzzles
*/
const MapChrome: React.FC<{children: JSX.Element, completed: Array<boolean>}> = ({children, completed}) => {
  return (
    <>
      <div style={ { display: "fixed", width: "100%", height: "100%", margin: "0", backgroundColor: Colors.Eggshell, userSelect: 'none', touchAction: 'none'}}>
        <div style={{padding: "0.5rem"}}>
          <ProgressBar completed={completed} />

        </div>
        { children }
      </div>
    </>
  )
}

const ScavengerHuntVisualMap = ({seenMap, completed, pins}: {seenMap: boolean, completed: Array<boolean>, pins: MapPin[]}) => {
  const [showCompleted, setShowCompleted] = useState(true);
  const layers = useMemo(() => {
    const pinLayer = new PinMapLayer();
    for (const pin of pins) {
      pinLayer.addPin(pin);
    }
    return [pinLayer];
  }, [pins]);

  const mapDelegate = useMemo(() => new ScavengerHuntMapDelegate(layers), [layers]);

  let seenMapElement = (seenMap ? 
    <></> : 
    <ScavengerHuntPopover button={
      {
        label: "Open Map",
        callback: () => {
          localStorage.setItem(ScavengerHuntQRCodes.map, "completed");
          window.location.assign("/scavenger_hunt_map");
        }
      } 
    }>
      <h3>Welcome to the Scavenger Hunt!</h3>
      <p>Follow the map to find and solve three challenges to win a prize!</p>
    </ScavengerHuntPopover>
  );

  let completedElement = ( completed[0] && completed[1] && completed[2] && showCompleted ? 
    <ScavengerHuntPopover button={
      {
        label: "Open Map",
        callback: () => {
          setShowCompleted(false);
        }
      } 
    }>
      <h3>You Completed all the Challenges</h3>
      <p>Head to the MADE's booth to collect your prize!</p>
    </ScavengerHuntPopover> 
    : <></> 
  );

  return (
    <>
      <MapChrome completed={completed}>
        <Canvas renderer={ mapDelegate } ></Canvas>
      </MapChrome>
      { seenMapElement }
      { completedElement }
    </>
  )
}

const ScavengerHuntPopover: React.FC<{button: {label: string, callback: (() => void)}, children:ReactNode}> = ({ button, children }) => {
  return (
    <Popover
      open
      anchorReference={ "none" }
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }} 
    >
      <Typography sx={{ p: "20px" }}>{ children }</Typography>
      <Button sx={{ margin: "20px" }} variant="contained" onClick={ button.callback }>{ button.label }</Button>
    </Popover>
  );
}

const ScavengerHuntInputWithSuggestions = ({ suggestions, onSubmit }: { suggestions: string[], onSubmit: (value: string) => void }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setFilteredSuggestions(suggestions.filter(suggestion => suggestion.toLowerCase().includes(value.toLowerCase())).slice(0, 5));
    setAnchorEl(event.currentTarget);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setInputValue(suggestion);
    setFilteredSuggestions([]);
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    onSubmit(inputValue);
    setInputValue("");
    setFilteredSuggestions([]);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Answer"
        style={{ padding: "8px", width: "100%", boxSizing: "border-box" }}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <div>
          {filteredSuggestions.map((suggestion, index) => (
            <Typography
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              sx={{ padding: "8px", cursor: "pointer" }}
            >
              {suggestion}
            </Typography>
          ))}
        </div>
      </Popover>
      <Button variant="contained" onClick={handleSubmit} style={{ marginTop: "8px" }}>Submit</Button>
    </Box>
  );
};

const ScavengerHuntStickyFooter: React.FC = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(() => createTheme(
    getDesignTokens(
      prefersDarkMode ? "dark" : "light")
    ),
    [
      prefersDarkMode ? "dark" : "light"
    ]
  );

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        padding: '0.1rem',
        textAlign: 'center',
        boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={ 2 } alignItems="center" justifyContent="center">
          <Grid item sx={{padding: '0' }} >
            <a href="/scavenger_hunt_map"><img src="/logo256d.png" width="48px" /></a>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="textSecondary">
              <a href="/scavenger_hunt_map">SCAVENGER HUNT</a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const ScavengerHuntQuizContent = ({ qrCode, huntItem, completed, }: {
  qrCode: string, 
  huntItem: ScavengerHuntItem, 
  completed: Array<boolean> }) => {

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const solved_text = "Challenge completed!";
  const requestHeaders: HeadersInit = new Headers({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(`/interactive/scavhunt_${qrCode}.json`, { headers: requestHeaders });
        const json = await res.json();
        setSuggestions(json);
      } catch (error) {
        console.log("Failed to fetch data:", error);
      }
    }
    fetchData();
  }, [suggestions]);
  
  let bodyEl = document.getElementsByName("body")[0];
  let completedElement = ( 
    qrCode === ScavengerHuntQRCodes.zero && completed[0] ||
    qrCode === ScavengerHuntQRCodes.one && completed[1] ||
    qrCode === ScavengerHuntQRCodes.two && completed[2] ? 
    <ScavengerHuntPopover button={ 
      {
        label: "Open Map",
        callback: () => { window.location.assign("/scavenger_hunt_map"); } 
      }
    }>
      { solved_text }
    </ScavengerHuntPopover> : <></>
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submit = (inputValue: String) => {
    if  (inputValue === huntItem.answer) {
      if (qrCode == ScavengerHuntQRCodes.zero) {
        localStorage.setItem(ScavengerHuntQRCodes.zero, "completed");
      }
      if (qrCode == ScavengerHuntQRCodes.one) {
        localStorage.setItem(ScavengerHuntQRCodes.one, "completed");
      }
      if (qrCode == ScavengerHuntQRCodes.two) {
        localStorage.setItem(ScavengerHuntQRCodes.two, "completed");
      }
    }
    window.location.reload();
  }

  return (
    <>
      <QuoteSplash smallImage="" bigImage="">
        <QuoteSplashTitle>{ huntItem.title }</QuoteSplashTitle>
        <QuoteSplashSubtitle>
          <p>
            { huntItem.content }
          </p>
          {/* <input type="text" placeholder="Answer" onChange={  } /> */}
          <ScavengerHuntInputWithSuggestions suggestions={ suggestions } onSubmit={ submit } />
        </QuoteSplashSubtitle>
      </QuoteSplash>
      { completedElement }
    </>
  );
}

const ScavengerHuntValidate = ({ huntItem, hasFinished }: { huntItem: ScavengerHuntItem, hasFinished: boolean }) => {
  return (
    <>
      <QuoteSplash smallImage="" bigImage="">
        <QuoteSplashTitle>{ huntItem.title }</QuoteSplashTitle>
        <QuoteSplashSubtitle>
          { huntItem.content }
          { hasFinished? <p>Scavenger hunt completed!</p> : <p>Scavenger hunt incomplete!</p> }
        </QuoteSplashSubtitle>
      </QuoteSplash>
    </>
  );
}

const ScavengerHuntItem = ({ exhibit_id }: { exhibit_id: string }) => {
  const huntItem = scavengerHuntSequence[exhibit_id];
  const [seenMap, setSeenMap] = useState(false);
  const [completed, setCompleted] = useState([false, false, false]);

  useEffect(() => {
    const hunt_0 = localStorage.getItem(ScavengerHuntQRCodes.zero) !== null;
    const hunt_1 = localStorage.getItem(ScavengerHuntQRCodes.one) !== null;
    const hunt_2 = localStorage.getItem(ScavengerHuntQRCodes.two) !== null;
    const map = localStorage.getItem(ScavengerHuntQRCodes.map) !== null;

    setSeenMap(map);
    setCompleted([hunt_0, hunt_1, hunt_2]);
    
    return () => { };
  }, []);


  if (
    exhibit_id === ScavengerHuntQRCodes.map
  ) {
    return ScavengerHuntVisualMap(
      { 
        seenMap: seenMap, 
        completed: completed, 
        pins: [
          new MapPin(0.12, 0.0, ""),
          new MapPin(0.25, 0.37, ""),
          new MapPin(0.73, -0.25, "")
        ],
      }
    );
  }

  if (exhibit_id === ScavengerHuntQRCodes.reset) {
    localStorage.removeItem(ScavengerHuntQRCodes.zero);
    localStorage.removeItem(ScavengerHuntQRCodes.one);
    localStorage.removeItem(ScavengerHuntQRCodes.two);
    localStorage.removeItem(ScavengerHuntQRCodes.map);
    return ScavengerHuntReset();
  }

  return ScavengerHuntQuizContent({ qrCode: exhibit_id, huntItem, completed: completed });
}

export const ScavengerHuntWidget = ({ exhibit_id }) => {
  if (!exhibit_id) {
    exhibit_id = ScavengerHuntQRCodes.map;
  };

  if (!scavengerHuntSequence[exhibit_id]) {
    console.error('unknown scavenger hunt exhibit_id');
    return <ScavengerHuntInvalidLanding />;
  }

  return <>
    <ScavengerHuntItem exhibit_id={exhibit_id} />
    <ScavengerHuntStickyFooter />
  </>;
}

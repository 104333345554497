import React from "react";
import { MADECanvasRenderer, GRERenderer } from "renderer/Renderer";
import { GREMADEProperties } from "widgets/Types/Types";


export const GREMADEWidget: React.FC<GREMADEProperties> = () => {
    const renderer:MADECanvasRenderer = new GRERenderer()
    return (
        <>
            {/* <Container>
                <Row>
                    <Canvas renderer={ renderer } id="webgl-canvas" style={ {width: "100%", height: "50%", marginBottom: "20px"} }></Canvas>
                    <br />
                </Row>
            </Container> */}
        </>
    );
} 
import React, { Fragment } from 'react';
import { Sectional } from 'layout/Sectional';
import { TopMenu } from 'layout/TopMenu';
import { FooterWidget } from 'layout/Footer';

export const PathError: React.FC = () => {

    const pushTop = (
        <div style={ { "marginTop": "60px"} } className="pushTop"></div>
      );

      const topMenu = (
        <TopMenu/>
      );

      const footerWidget = (
        <FooterWidget />
      )
      
    return (<Fragment>
            { topMenu }
            { pushTop }
            <Sectional title="404 Error">
                The page you're looking for either no longer exists, or it never did. Try going back to the <a href="/">homepage</a>, and navigate around to find what you're looking for.
            </Sectional>
                
            { footerWidget }
        </Fragment>
    )
};

export default PathError;

import { AcUnitTwoTone } from "@mui/icons-material";
import { Box, Container, Grid, List, ListItem, ListItemButton, ListItemText, ListSubheader } from "@mui/material";
import React, { useState } from "react"
import { Canvas } from "renderer/ExhibitRenderer"
import { MADECanvasRenderer } from "renderer/Renderer";
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

type FloorCamera = {
    lookAt: number[],
    position: number[]
}

type MuseumFloor = {
    modelDirectory: string,
    fileName: string,
    offset: number[],
    camera: FloorCamera,
    description: string
}

export class InteractiveMapExperience {
    canvas: HTMLCanvasElement;
    renderer: THREE.WebGLRenderer;
    scene: THREE.Scene;
    camera: THREE.PerspectiveCamera;
    // controls: OrbitControls;
    light: THREE.AmbientLight;
    height: number;
    currentFloor: number;

    floors: MuseumFloor[] = [
        {
            "modelDirectory": "/models/",
            "fileName": 'swans_beauty_stripped_bot_001.glb',
            "offset": [0, 0, 1],
            "camera": {
                "lookAt": [0, -5, 0],
                "position": [22, 22.0, -23]
            },
            "description": ""
        },
        {
            "modelDirectory": "/models/",
            "fileName": 'swans_beauty_stripped_top_001.glb',
            "offset": [0, 60, 1],
            "camera": {
                "lookAt": [0, 55, 0],
                "position": [22, 82.0, -23]
            },
            "description": ""
        },
    ]

    constructor(canvas: HTMLCanvasElement) {
        this.height = 700;
        this.canvas = canvas;
        this.renderer = new THREE.WebGLRenderer( { antialias: true, canvas: this.canvas } );
        this.renderer.setClearColor(new THREE.Color("#fff"));
        this.currentFloor = 0;

        this.camera = new THREE.PerspectiveCamera( 40, this.canvas.offsetWidth / this.height, 1, 1000 );
        this.renderer.setSize( canvas.offsetWidth, canvas.offsetHeight );
        this.scene = new THREE.Scene();
        this.light = new THREE.AmbientLight();
        this.scene.add(this.light);
        
        this.floors.forEach((floor) => {
            const loader = new GLTFLoader().setPath( floor.modelDirectory );
            loader.load( floor.fileName, ( gltf ) => {
                const parent = gltf.scene.children[0];
                const mesh = parent.children[0] as THREE.Mesh;
                parent.translateX(floor.offset[0]);
                parent.translateY(floor.offset[1]);
                parent.translateZ(floor.offset[2]);
                (mesh.material as THREE.MeshStandardMaterial)["color"] = new THREE.Color("#333");
                (mesh.material as THREE.MeshStandardMaterial)["wireframe"] = true;
                this.scene.add( gltf.scene );
                this.render();
            });
        });

        this.updateFloorCamera();

        // this.controls = new OrbitControls( this.camera, this.canvas);
        // this.controls.minDistance = 40;
        // this.controls.maxDistance = 50;
        // this.controls.target.set( 0, -5, 0);
        // this.controls.update();
        

	    // window.addEventListener( 'resize', this.onWindowResize );
        this.animate();
    }

    updateFloorCamera() {
        this.camera.position.set(
            this.floors[this.currentFloor].camera.position[0],
            this.floors[this.currentFloor].camera.position[1],
            this.floors[this.currentFloor].camera.position[2]
        );
        this.camera.lookAt(
            this.floors[this.currentFloor].camera.lookAt[0],
            this.floors[this.currentFloor].camera.lookAt[1],
            this.floors[this.currentFloor].camera.lookAt[2],
        );
    }

    switchFloor(floor: number) {
        this.currentFloor = floor;
        this.updateFloorCamera();
    }

    onWindowResize(width: number, height: number, mapExperience: InteractiveMapExperience) {
        mapExperience.camera.aspect = window.innerWidth / window.innerHeight;
        mapExperience.camera.updateProjectionMatrix();

        // this.renderer.setSize( this.canvas.offsetWidth, this.canvas.offsetHeight );
        mapExperience.renderer.setSize( width, height );

        mapExperience.render();
    }

    animate() {
        requestAnimationFrame(() => this.animate());
        
        // this.controls.update()
        this.render();

    }

    render() {
        if (this.renderer !== undefined) {
            this.renderer.render( this.scene, this.camera );
        }
    }
}


export class InteractiveMapDelegate implements MADECanvasRenderer {

    mapExperience?: InteractiveMapExperience

    getDescription(floor: number) {
        return this.mapExperience?.floors[floor].description;
    }

    changeFloor(floor: number) {
        this.mapExperience?.switchFloor(floor)
    }

    onWindowResize(width:number, height:number, mapExperience: InteractiveMapExperience) {
        console.log(this.mapExperience)
        this.mapExperience?.onWindowResize(width, height, mapExperience);
    }
    
    start(canvas: HTMLCanvasElement) {
        this.mapExperience = new InteractiveMapExperience(canvas);
        return true;
    }

}


export const InteractiveMapWidget: React.FC = () => {

    const [floor, setFloor] = useState(0);
    var renderer = new InteractiveMapDelegate();
    // var description =
    const changeFloor = (mapFloor) => {
        renderer.changeFloor(mapFloor);
    };

    var description = renderer.getDescription(floor);

    return (
        <Container>
            <Grid container>
                <Grid item sm={12} md={8} lg={8}>
                    <Box sx={{ width: "100%" }}>
                        <Canvas style={{ width: "100%"}} renderer={ renderer } windowResizeHandler={ renderer.onWindowResize }></Canvas>
                    </Box>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Floor
                        </ListSubheader>
                    }>
                        <ListItemButton onClick={ () => {
                            changeFloor(0)
                        } }>
                            <ListItemText primary="First Floor" />
                        </ListItemButton>
                        <ListItemButton onClick={ () => {
                            changeFloor(1)
                        } }>
                            <ListItemText primary="Second Floor" />
                        </ListItemButton>
                        <ListItem>
                            {
                                description
                            }
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Container>
    )

}
import React from 'react';

import { createRoot } from 'react-dom/client';


import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import App from './App';
import './index.css';
import { GRERenderer } from 'renderer/Renderer';

// TODO: Shem -- finish work on baking static pages to drop server costs
// render(
//   <App/>,
//   document.getElementById('root')
// );


const container = document.getElementById('app');
const root = createRoot(container as Element);
root.render(<App />);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import { Sectional } from "layout/Sectional";
import Splash from "layout/Splash";
import React, { useEffect, useMemo, useRef } from "react";
import { Redirect } from 'react-router-dom';


export const TrackableGeneratorWidget: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const url:string = queryParams.get("url") === null ? "https://www.themade.org": queryParams.get("url")!;

    setTimeout(()=> {
        // _gaq.push(['_trackEvent','Installer','Download','Mac']);
        window.location.replace(url);
    }, 1500);

    return (
        <>
            This page will redirect you in 1.5 seconds. If it does not, please click <a href={url}>here</a>.
        </>
    );
}

import React, { useEffect, useRef, useState } from 'react';
import { GRERenderer, MADECanvasRenderer } from 'renderer/Renderer'

import { Canvas, ShaderCanvas, GeometryExhibitDelegate, ExhibitExperience, ShaderExhibitDelegate, StateMachineExhibitDelegate, P5Container, MapExhibitDelegate } from "renderer/ExhibitRenderer";
import P5 from 'p5';
import { ExhibitStorage, ExhibitStoragePrototype } from 'renderer/exhibits/ExhibitStorage';
import { Box, Button } from '@mui/material';

type Exhibit3DProperties = {
    exhibitID: number
}

export const GeometryExhibit: React.FC<Exhibit3DProperties> = ({ exhibitID }) => {
    let [visible, setVisible] = useState(true);
    let [solved, setSolved] = useState(false)

    const renderer:GeometryExhibitDelegate = new GeometryExhibitDelegate(setSolved)

    var puzzle = <>
        <Canvas renderer={ renderer } style={ {width: "100%", height: "100%", margin: "0"} }></Canvas>
        <Box style={{ 
            position: "fixed",
            display: visible ? "block" : "none",
            width: "80%", 
            top: "20%",
            left: "10%",
            background: "#aaa",
            borderRadius: "10px", 
            padding: "20px" 
        }}>
            <p>
                <b>Description</b><br/>
                How does geometry play a role in game development? Programmers bring ideas to life in many games using a series of triangles to craft shapes, form complex objects, and draw them in a digital world.
            </p>
            <p>
                <b>Action</b><br/>
                Draw triangles to build a 3D model of your virtual object. Each triangle you make crafts a larger 3D shape and serves as the basis for object creation in games!
            </p>
            <Button onClick={ () => { setVisible(false) } } style={{ width: "100%", margin: "0 0 0 0" }}>Click Here to Begin</Button>
        </Box>
    </>
    var win = <>
        <div style={{ 
            position: "fixed",
            display: "block",
            width: "80%", 
            top: "20%", 
            backgroundColor: "#eee", 
            left: "10%",
            borderRadius: "10px", 
            padding: "20px" 
        }}>
            You successfully solved this puzzle! Go find other puzzles using the scavenger hunt map: <a href="/nightlife-exhibit_map">Exhibit Map</a>.
        </div>
    </>

    return (
        <>
            { solved ? win : puzzle }
        </>
    );
}


export const ShaderExhibit: React.FC<Exhibit3DProperties> = ({ exhibitID }) => {
    let [visible, setVisible] = useState(true)
    let [solved, setSolved] = useState(false)

    const renderer:ShaderExhibitDelegate = new ShaderExhibitDelegate(setSolved)
    const exhibitStorage:ExhibitStoragePrototype = ExhibitStorage

    const [fragmentShader, setFragmentShader] = useState(
        `
        varying vec3 vUv;
        void main() {
        gl_FragColor = vec4(0.0, 0.0, 1.0, 1.0);
        }
        `
    );

    var createFragment = (r: number, g: number, b: number) => {
        var shader = `varying vec3 vUv; void main() { gl_FragColor = vec4(${r}, ${g}, ${b}, 1.0);}`
        return shader
    }

    var cr:number = 0.0
    var cg:number = 0.0
    var cb:number = 1.0

    var win = <div style={{ 
        position: "absolute",
        width: "80%",
        top: "20%", 
        left: "10%",
        backgroundColor: "#eee", 
        borderRadius: "10px", 
        padding: "20px",
        zIndex: 999999
    }}>
        You successfully solved this puzzle! Go find other puzzles using the scavenger hunt map: <a href="/nightlife-exhibit_map">Exhibit Map</a>.
    </div>

    var execute = <Button sx={ { position: "absolute", right: "0", margin: "10px" }} onClick={ () => {
        renderer.recompileShader(createFragment(cr, cg, cb))
        // setFragmentShader(createFragment(cr, cg, cb))
        if (cr >= 1.0 && cg == 0.0 && cb == 0.0) {
            exhibitStorage.exhibit_two = true
            setSolved(true)
        }
    }}>
        Check Shader
    </Button>

    var puzzle = <>
        <div style={{display: solved || visible ? "none" : "block", position: "absolute", width: "100%", fontFamily: "monospace", top: "0", color: "#fff" }}>
            <div>
                <p>
                varying vec3 vUv;<br/>
                void main() { "{" }<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;gl_FragColor = vec4(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<input defaultValue={ cr } type="number" min="0.0" max="1.0" onChange={
                    (event) => {
                        cr = event.target.valueAsNumber
                    }
                }/>,<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<input defaultValue={ cg } type="number" min="0.0" max="1.0" onChange={
                    (event) => {
                        cg = event.target.valueAsNumber
                    }
                }/>,<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<input defaultValue={ cb } type="number" min="0.0" max="1.0" onChange={
                    (event) => {
                        cb = event.target.valueAsNumber
                    }
                }/>,<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;1.0
                ); <br/>
                { "}" }
                </p>
            </div>
        </div>
        <div style={{ 
            position: "fixed",
            display: visible ? "block" : "none",
            width: "80%", 
            top: "20%", 
            backgroundColor: "#eee", 
            left: "10%",
            borderRadius: "10px", 
            padding: "20px" 
        }}>
            <p>
                <b>Description</b><br/>
                A shader is a development tool that brings color to shapes in games according to a procedural function. These shapes can show off complex coloring, gradients, and patterns to detail a player’s experience.
            </p>
            <p>
                <b>Action</b><br/>
                Color the cube red by adjusting the four color variants available: red, green, blue, and alpha (which represents transparency).
            </p>
            <Button onClick={ () => { setVisible(false) } }>Click Here to Begin</Button>
        </div>
    </>

    return (
        <>
            <ShaderCanvas renderer={ renderer } fragmentshader={ fragmentShader } style={ 
                {width: "100%", height: "100%", margin: "0", position: "absolute", top: "0", zIndex: "-9999"} 
            }> 
            </ShaderCanvas>
            { solved ? win : puzzle }
            { solved ? <></> : execute }
        </>
    );
}

export const StateMachineExhibit: React.FC<Exhibit3DProperties> = ({ exhibitID }) => {
    let [visible, setVisible] = useState(true);
    let [solved, setSolved] = useState(false);

    const renderer:StateMachineExhibitDelegate = new StateMachineExhibitDelegate(setSolved);

    let prompt = visible && !solved ? <div style={{ 
        position: "fixed",
        display: visible ? "block" : "none",
        width: "80%", 
        top: "20%", 
        backgroundColor: "#eee", 
        left: "10%",
        borderRadius: "10px", 
        padding: "20px" 
    }}>
        <p>
            <b>Description</b><br/>
            A state machine is a programming tool that defines the transition from one key moment in a game to another. It’s an organizational feature that helps bring structure to the development process and provides a clear understanding of the player journey.
        </p>
        <p>
            <b>Action</b><br/>
            Try to make a game using the states provided. If you’ve never built a video game before, consider this your first one!
        </p>
        <Button onClick={ () => { setVisible(false) } }>Click Here to Begin</Button>
    </div> : <></>
    
    // let puzzle = !visible && !solved ? <P5Container renderer={ renderer }></P5Container> : <></>
    let puzzle = <P5Container renderer={ renderer }></P5Container>

    var win = <div style={{ 
        position: "absolute",
        width: "80%",
        top: "20%", 
        left: "10%",
        backgroundColor: "#eee", 
        borderRadius: "10px", 
        padding: "20px",
        zIndex: 999999
    }}>
        You successfully solved this puzzle! Go find other puzzles using the scavenger hunt map: <a href="/nightlife-exhibit_map">Exhibit Map</a>.
    </div>

    return (
        <>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            { puzzle }
            { prompt }
            { solved ? win : <></> }
        </>
    );
}

export const MapExhibit: React.FC<Exhibit3DProperties> = ({ exhibitID }) => {
    const renderer:MapExhibitDelegate = new MapExhibitDelegate();

    return (
        <>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            <P5Container renderer={ renderer }></P5Container>
        </>
    )
}

export const ResetExhibit: React.FC = () => {
    ExhibitStorage.exhibit_one = false
    ExhibitStorage.exhibit_two = false
    ExhibitStorage.exhibit_three = false
    
    return (
        <div>
            Exhibits Reset!
        </div>
    )
}

export const CompletedExhibit: React.FC = () => {
    const completed = ExhibitStorage.exhibit_one && 
        ExhibitStorage.exhibit_two && 
        ExhibitStorage.exhibit_three;

    const completeElement = completed ? <h1>
        You Finished! Go to the MADE logo on the <a href="/nightlife-exhibit_map">Scavenger Hunt Map</a>.
    </h1> : <h1>You still haven't finished! Use the <a href="/nightlife-exhibit_map">Scavenger Hunt Map</a> to find the mini-game you haven't completed!</h1>
    
    return (
        <div style={{ textAlign: "center", margin: 10 }}>
            <img src="/logo256.png"></img>
            { completeElement }
        </div>
    )
}

import Splash from "layout/Splash";
import React from "react";
import { VolunteerFormProperties } from "widgets/Types/Types";

export const VolunteerFormWidget: React.FC<VolunteerFormProperties> = ({ url }) => {

    return (
        <>
            <Splash bg="light_cool" color="dark_cool_text" align="full">
                <iframe src={ url } width="800" height="600" scrolling="yes" style={{display: "block", border: "none", margin: "0 auto"}} title="Volunteer Form"></iframe>
            </Splash>
        </>
    );

}

import {ZoomPanPinchControls} from "ZoomPanPinchControls";
import { MADECanvasRenderer } from "renderer/Renderer";
import * as THREE from 'three';
import {LIGHT_DATA, STARTING_CAMERA_POSITION} from "./constants";
import { MapLayer } from "./MapLayer";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";


class ScavengerHuntMapRenderer {
  canvas: HTMLCanvasElement
  renderer: THREE.WebGLRenderer
  scene: THREE.Scene
  camera: THREE.PerspectiveCamera
  controls: ZoomPanPinchControls
  layers: MapLayer[] = []

  init =async () => {
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( window.innerWidth, window.innerHeight );
    const loader = new GLTFLoader();
    const gltf = await loader.loadAsync('/models/event_cas_supernatural_2024_10.0003.glb')
    const {scene: mapMesh} = gltf

    // TODO fix model scale and rotation?
    mapMesh.rotateX(Math.PI / 2)
    mapMesh.scale.setScalar(0.1)

    // enable shadows
    mapMesh.traverse((child) => {
      if(child instanceof THREE.Mesh) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });

    this.scene.add(mapMesh);

    for (const lightDatum of LIGHT_DATA) {
      const lightInstance = new lightDatum.type(lightDatum.color, lightDatum.intensity);
      if(lightDatum.type === THREE.DirectionalLight) {
        lightInstance.castShadow = true;
        lightInstance.position.copy(lightDatum.position!);
        lightInstance.lookAt(lightDatum.lookAt!);
      }
      this.scene.add(lightInstance);
    }

    this.camera.position.copy(STARTING_CAMERA_POSITION);

    this.controls.start();

  }

  animate = () => {
    requestAnimationFrame(() => this.animate());

    for(const layer of this.layers) {
      layer.animate(this.camera.position);
    }

    this.render();
  }

  render = () => {
    this.renderer.render( this.scene, this.camera );
  }

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color( 0xffffff );
    // Allow camera Z value to be very small with the map still visible
    this.camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 0.0001, 1000 );
    this.renderer = new THREE.WebGLRenderer( { antialias: true, canvas: this.canvas } );
    this.controls = new ZoomPanPinchControls(this.renderer.domElement, this.camera.position);

    this.init();
    this.animate();

    window.addEventListener( 'resize', (event) => this.onWindowResize(event) );
  }


  onWindowResize(event) {
    event.preventDefault();
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
    document.getElementsByTagName("html")[0].style.height = "100%";
    document.getElementById("app")!.style.height = "100%";
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( window.innerWidth, window.innerHeight );

    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
  }

  addLayer(layer: MapLayer) {
    this.layers.push(layer);
    this.scene.add(layer);
  }
}

export class ScavengerHuntMapDelegate implements MADECanvasRenderer {
  canvas?: HTMLCanvasElement
  renderer?: ScavengerHuntMapRenderer

  constructor(readonly layers: MapLayer[]) {
  }

  start(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.renderer = new ScavengerHuntMapRenderer(canvas);

    for(const layer of this.layers) {
      this.renderer.addLayer(layer);
    }

    return true
  }
}

export interface MADECanvasRenderer {
    start:(canvas: HTMLCanvasElement) => void
}

export interface MADEP5Renderer {
    start:(p5Container: HTMLDivElement) => void
}

// interface GREMesh {
//     vertex: string,
//     fragment: string,
//     vertices: number[]
// }

// class GRESurface implements GREMesh {
//     vertex = ""
//     fragment = ""
//     vertices = [
//         -1.0,  1.0, 0.0,
//         -1.0, -1.0, 0.0,
//          1.0,  1.0, 0.0,
//          1.0, -1.0, 0.0,
//     ]
// }

// class GREShader {
// }

export class GRERenderer implements MADECanvasRenderer {
    canvas?: HTMLCanvasElement
    context?: WebGLRenderingContext
    i: number = 0
    
    // constructor() {
    //     var vertex = ""
    //     var fragment = ""
    //     var vertices = [0.0]
    // }

    tick() {
        window.requestAnimationFrame(() => this.tick);
        console.log("ticking on canvas " + this.i++)
    }

    start(canvas: HTMLCanvasElement) {
        this.canvas = canvas
        var c = canvas.getContext('webgl')
        if (c != null) {
            this.context = c
        } else {
            console.log("failed")
            return false
        }
        return true
    }
    
}
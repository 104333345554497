import { useMediaQuery, createTheme, Grid, Button } from "@mui/material";
import { getDesignTokens } from "AppGlobals";
import { Sectional } from "layout/Sectional";
import React from "react";


export const PlexusPlayWidget: React.FC = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(
    getDesignTokens(
      prefersDarkMode ? "dark" : "light")
  ),
    [
      prefersDarkMode ? "dark" : "light"
    ]
  );

  let color = prefersDarkMode ? "0%" : "65%";


  return (
    <Sectional title="Here's How to Join:">
      <h2>For more details and to buy tickets, press the button to go to Eventbrite</h2>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Button href="https://www.eventbrite.com/e/plexusplay-the-made-tickets-669158750837?aff=oddtdtcreator" formTarget="" style={{ width: "100%", margin: "0 0 0 0" }}>
            <img width="100%" src="img/sponsor/logo-wordmark-white.png" style={{ color: "#fff", filter: "invert(" + color + ")", }} alt="EventBrite" />
          </Button>
          <h2>*MADE Yearly Members can access this event for free</h2>
        </Grid>
      </Grid>
    </Sectional>
  );
}

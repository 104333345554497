import { ExhibitExperience } from '../ExhibitRenderer';
import * as THREE from 'three';
import { ExhibitStorage, ExhibitStoragePrototype } from './ExhibitStorage';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export class ShaderExperience implements ExhibitExperience {
    canvas: HTMLCanvasElement
    renderer: THREE.Renderer
    scene: THREE.Scene
    camera: THREE.PerspectiveCamera
    cube: THREE.Mesh
    vertexShader: string = `
    varying vec3 vUv;

    void main() {

    vUv = position.xyz; 
    vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * modelViewPosition;
    }
    `
    controls: OrbitControls;
    
    fragmentShader: string
    solvedCallback: React.Dispatch<React.SetStateAction<boolean>> | undefined

    render() {
        this.renderer.render(this.scene, this.camera);
    }

    animate() {
        requestAnimationFrame(() => this.animate());
        this.controls.update()

        this.render()
        
    }

    compile(fragmentShader: string) {
        console.log("---> compiling?")
        this.fragmentShader = fragmentShader;
        (this.cube.material as THREE.ShaderMaterial).fragmentShader = fragmentShader;
        (this.cube.material as THREE.ShaderMaterial).fragmentShader = fragmentShader;
        (this.cube.material as THREE.ShaderMaterial).needsUpdate = true;

    }

    resize() {
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
    }

    onPointerDown( event ) {
        event.preventDefault();
    }
    
    constructor (canvas: HTMLCanvasElement, fragmentShader: string) {
        this.canvas = canvas;
        this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas });
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.01, 50 );

        this.fragmentShader = fragmentShader
        this.scene.background = new THREE.Color( 0x444444 );


        // this.camera.position.z = 2
        this.controls = new OrbitControls( this.camera, this.canvas);
        this.controls.minDistance = 2;
        this.controls.maxDistance = 4;
        this.controls.maxPolarAngle = Math.PI / 2;
        this.controls.enableDamping = true;
        this.controls.enableZoom = false;
        this.controls.enablePan = false;
        this.controls.dampingFactor = 0.1;
        this.controls.update()


        const geometry = new THREE.BoxGeometry( 1, 1, 1 );
        // const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );

        const material = new THREE.ShaderMaterial( 
            {
                uniforms: {
                    time: { value: 1.0 },
                    resolution: { value: new THREE.Vector2() }
            
                },
                vertexShader: this.vertexShader,
                fragmentShader: this.fragmentShader
            } 
        );
        
        this.cube = new THREE.Mesh( geometry, material );
        this.cube.renderOrder = 0
        this.scene.add( this.cube );


        // window.onresize = () => this.resize();
        window.addEventListener( 'resize', (event) => this.resize() );
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        this.start()
    }

    start() {
		this.animate();
    }
    
    exhibitStorage: ExhibitStoragePrototype = ExhibitStorage
}
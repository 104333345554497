import { Sectional } from "layout/Sectional";
import Splash from "layout/Splash";
import React, { useEffect, useMemo, useRef } from "react";
import QRCode from "qrcode-svg";

export const QRCodeGeneratorWidget: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const width:string = queryParams.get("width") === null ? "256": queryParams.get("width")!;
    const height:string = queryParams.get("height") === null ? "256": queryParams.get("height")!;
    const url:string = queryParams.get("url") === null ? "https://www.themade.org": queryParams.get("url")!;
    const color:string = queryParams.get("color") === null ? "#ff0000": queryParams.get("color")!;
    const background:string = queryParams.get("background") === null ? "#ffffff": queryParams.get("background")!;

    const ref = useRef(null);

    const svg = useMemo( 
        () => {
            var qrcode = new QRCode({
                content: url,
                padding: 4,
                width: Number(width),
                height: Number(height),
                color: String(color),
                background: background,
                ecl: "M",
            });

            return qrcode.svg()
        },
        []
    );

    useEffect(() => {
        fetch("/navigation/pages.json")
        .then(res => res.json())
        .then(
            (result) => {
                const canvas:HTMLCanvasElement | null = ref.current;
                if (canvas !== null) {
                    const c = (canvas as HTMLCanvasElement)
                    const image = new Image();
                    if (ref.current === null) return;
                    const context = c.getContext("2d");
                    
                    image.src = `data:image/svg+xml;base64,${window.btoa(svg)}`;
                    image.onload = () => {
                        if (context !== null) {
                            context.drawImage(image, 0, 0);
                        }
                    };
                }
            },
            (error) => {
            }
        )

      }, [svg]);

    return (
        <canvas width={ String(width) } height={ String(height) } ref={ref} />
    );
}

import { Container } from "@mui/material";
import Splash from "layout/Splash";
import React from "react";
import { EventCalendarProperties } from "widgets/Types/Types";



export const EventCalendarWidget: React.FC<EventCalendarProperties> = ({ url }) => {

    return (
        <>
            <Container>
                <iframe src={ url } width="100%" height="600px" scrolling="no">
                </iframe>
            </Container>
        </>
    );
}
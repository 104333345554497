import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import { ImageSectional } from 'layout/ImageSectional';
import { Sectional } from 'layout/Sectional';
import React from 'react';

type YoutubeProperties = {
    title: string,
    url: string,
    subtitle?: string,
    height?: string,
    component?: boolean
}

export const YoutubeWidget: React.FC<YoutubeProperties> = ({title, url, subtitle=undefined, height="340px", component=true}) => {
    if (component) {
        if (subtitle) {
            return (
                <Sectional title={ title }>
                    <Grid container spacing={2}>
                        <Grid item xs={ 12 } md={8} lg={9}>
                            <iframe width="100%" height={ height } src={ url } title={ title } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                            </iframe>
                        </Grid>
                        <Grid item xs={12} sm={5} md={4} lg={3}>
                            { subtitle }
                        </Grid>
                    </Grid>
                </Sectional>
            );
        }
        return (
            <Sectional title={ title }>
                <iframe width="100%" height={ height } src={ url } title={ title } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                </iframe>
            </Sectional>
        );
    } else {
        return (
            <iframe width="100%" height={ height } src={ url } title={ title } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
            </iframe>
        );
    }
}

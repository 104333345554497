import { ExhibitExperience } from '../ExhibitRenderer';
import P5 from 'p5';
import { ExhibitStorage, ExhibitStoragePrototype } from './ExhibitStorage';
import { textChangeRangeIsUnchanged } from 'typescript';

interface GameState {
    name:string
    expectedNodes:number
    position: Point2D
    startOffset: Point2D
    stateView: GameStateView

    draw(p5: P5):void
    contains(point:Point2D):boolean
    dragging(location: Point2D)
    releasing(droppable: DroppableLocation | undefined)
}

type Point2D = {
    x: number,
    y: number
}

interface GameStateView {
    clicked(clickPoint:Point2D, gameView: GameView, stateGraph:GameStateGraph);
    draw(p5: P5, gameView: GameView):void;
}

abstract class Draggable implements GameState {

    contains(point: Point2D): boolean {
        if (this.snapped) { return false }
        let cmpx = point.x - this.position.x;
        let cmpy = point.y - this.position.y;
        if (
            cmpx < this.dimensions.x && cmpx > 0 &&
            cmpy < this.dimensions.y && cmpy > 0
        ) {
            return true;
        }
        return false;
    }

    startOffset: Point2D = { x: 0, y: 0 };
    abstract name: string;
    abstract stateView: GameStateView;
    expectedNodes:number = 1;
    position: Point2D;
    dimensions: Point2D = { x: 80, y: 30 }
    corners: number = 10;
    snapped: boolean = false;

    constructor(position: Point2D) {
        this.position = position;
        this.startOffset = position;
    }

    dragging(location: Point2D) {
        this.position = {
            x: location.x + this.startOffset.x,
            y: location.y + this.startOffset.y
        }
    }

    releasing(droppable: DroppableLocation | undefined) {
        console.log(droppable)
        if (droppable == undefined) {
            this.position = this.startOffset;
        } else {
            this.position = droppable.position;
            droppable.stateGraph.enabledStates[droppable.stateID] = true;
            this.snapped = true;
        }
    }

    draw(p5:P5): void {
        p5.stroke(0, 0, 0);
        p5.strokeWeight(2);
        p5.fill(255, 255, 255);
        p5.rect(
            this.position.x,
            this.position.y,
            this.dimensions.x,
            this.dimensions.y,
            this.corners,
            this.corners,
            this.corners,
            this.corners
        );
        p5.noStroke();
        p5.textSize(20);
        p5.fill(0, 0, 0);
        p5.text(this.name, this.position.x + 20, this.position.y + 23);
    }
}

class GameStateButton {
    text: string
    position: Point2D
    constructor(text: string, position: Point2D) {
        this.position = position
        this.text = text
    }
    dimensions: Point2D = {
        x: 130,
        y: 80
    }

    contains(offset: Point2D, point: Point2D):boolean {
        let cmpx = point.x - this.position.x - offset.x;
        let cmpy = point.y - this.position.y - offset.y;
        if (
            cmpx < this.dimensions.x && cmpx > 0 &&
            cmpy < this.dimensions.y && cmpy > 0
        ) {
            return true
        }
        return false
    }

    draw(p5: P5, gameView: GameView) {
        let gvp = gameView.gameSpacePosition
        p5.noStroke()
        p5.fill(0, 0, 0)
        p5.text(
            this.text, 
            gvp.x + this.position.x + 13, 
            gvp.y + this.position.y + 45
        )

        p5.stroke(50, 50, 50)
        p5.noFill()
        p5.rect(
            gvp.x + this.position.x, 
            gvp.y + this.position.y,
            this.dimensions.x,
            this.dimensions.y,
            10, 
            10, 
            10, 
            10
        )
    }
}

class IntroGameStateView implements GameStateView {
    button: GameStateButton
    buttonPosition: Point2D = {
        x: 70,
        y: 120
    }

    constructor() {
        this.button = new GameStateButton(
            "Play!",
            this.buttonPosition
        )
    }

    clicked(clickPoint: Point2D, gameView: GameView, stateGraph: GameStateGraph) {
        let gv = gameView.gameSpacePosition
        if (this.button.contains(gv, clickPoint)) {
            stateGraph.changeState("Play")
        }
    }

    draw(p5: P5, gameView: GameView): void {
        let gv = gameView.gameSpacePosition
        p5.noStroke()
        p5.fill(0, 0, 0)
        p5.text("Welcome to your first game!", gv.x + 20, gv.y + 50)

        this.button.draw(
            p5, gameView
        )
    }
}

class PlayGameStateView implements GameStateView {
    winButton: GameStateButton
    loseButton: GameStateButton
    winButtonPosition: Point2D = {
        x: 10,
        y: 120
    }

    loseButtonPosition: Point2D = {
        x: 160,
        y: 120
    }

    constructor() {
        this.winButton = new GameStateButton(
            "Win Here!",
            this.winButtonPosition
        )
        this.loseButton = new GameStateButton(
            "Lose Here!",
            this.loseButtonPosition
        )
    }

    clicked(clickPoint: Point2D, gameView: GameView, stateGraph: GameStateGraph) {
        let gv = gameView.gameSpacePosition
        if (this.winButton.contains(gv, clickPoint)) {
            stateGraph.changeState("Win")
        }
        if (this.loseButton.contains(gv, clickPoint)) {
            stateGraph.changeState("Lose")
        }
    }

    draw(p5: P5, gameView: GameView): void {
        let gv = gameView.gameSpacePosition
        p5.noStroke()
        p5.fill(0, 0, 0)
        p5.text("Play the Game!", gv.x + 10, gv.y + 50)

        this.winButton.draw(
            p5, gameView
        )

        this.loseButton.draw(
            p5, gameView
        )
    }
}

class LoseGameStateView implements GameStateView {
    clicked(clickPoint: Point2D, gameView: GameView, stateGraph: GameStateGraph) {
    }
    draw(p5: P5, gameView: GameView): void {
        let gv = gameView.gameSpacePosition
        p5.noStroke()
        p5.fill(0, 0, 0)
        p5.text("You lost your first game!", gv.x + 30, gv.y + 80)
    }
}

class WinGameStateView implements GameStateView {
    clicked(clickPoint: Point2D, gameView: GameView, stateGraph: GameStateGraph) {
    }
    
    draw(p5: P5, gameView: GameView): void {
        let gv = gameView.gameSpacePosition
        p5.noStroke()
        p5.fill(0, 0, 0)
        p5.text("You won your first game!", gv.x + 30, gv.y + 80)
    }

}

class IntroGameState extends Draggable {
    stateView: GameStateView = new IntroGameStateView();
    name:string = "Intro";
}
class PlayGameState extends Draggable {
    stateView: GameStateView = new PlayGameStateView()
    name:string = "Play"
}

class LoseGameState extends Draggable {
    stateView: GameStateView = new LoseGameStateView()
    name:string = "Lose"
}

class WinGameState extends Draggable {
    stateView: GameStateView = new WinGameStateView()
    name:string = "Win"
}

class DroppableLocation {
    relativePosition: Point2D
    dimensions: Point2D = { x: 80, y: 30}
    corners: number = 10
    stateID: string
    stateGraph: GameStateGraph

    constructor(position:Point2D, stateID: string, stateGraph: GameStateGraph) {
        this.stateID = stateID
        this.relativePosition = position
        this.stateGraph = stateGraph
    }

    get position():Point2D {
        return {
            x: this.stateGraph.position.x + this.relativePosition.x, 
            y: this.stateGraph.position.y + this.relativePosition.y, 
        }
    }

    draw(p5: P5) {
        // p5.strokeWeight(3)
        p5.stroke(200, 200, 200)
        p5.fill(180, 180, 180)
        p5.rect(
            this.position.x,
            this.position.y,
            this.dimensions.x, 
            this.dimensions.y, 
            this.corners, 
            this.corners, 
            this.corners, 
            this.corners
        )
    }

}

class GameStateGraph {
    currentGameState: GameState | undefined
    position: Point2D = {
        x: 10,
        y: 110
    }

    states: Array<GameState> = [
        new IntroGameState({ x: 10, y: 10}),
        new PlayGameState({ x: 100, y: 10}),
        new LoseGameState({ x: 10, y: 50}),
        new WinGameState({ x: 100, y: 50})
    ]

    enabledStates = {
        "Intro": true,
        "Play": false,
        "Lose": false,
        "Win": false
    }

    stateMap = {
        "Intro": 0,
        "Play": 1,
        "Lose": 2,
        "Win": 3,
    }

    droppables:Array<DroppableLocation> = []
    setSolved: React.Dispatch<React.SetStateAction<boolean>> | undefined

    changeState(stateID: string) {
        if (this.enabledStates[stateID]) {
            this.currentGameState = this.states[this.stateMap[stateID]]
        }
    }

    checkEndgame(): boolean {
        let stateID = this.currentGameState?.name
        if (stateID == "Win" || stateID == "Lose") {
            if (this.enabledStates.Intro &&
                this.enabledStates.Lose &&
                this.enabledStates.Win &&
                this.enabledStates.Play
            ) {
                // ExhibitStorage.exhibit_three = true;
                // console.log(`----------> ${this.setSolved}`)
                // if (this.setSolved != undefined) {

                //     this.setSolved(true)
                // }
                return true
            }
        }
        return false
    }

    constructor(setSolved: React.Dispatch<React.SetStateAction<boolean>> | undefined) {
        this.droppables = [
            new DroppableLocation(
                { x: 0, y: 10 },
                "Intro",
                this
            ),
            new DroppableLocation(
                { x: 200, y: 10 },
                "Play",
                this
            ),
            new DroppableLocation(
                { x: 0, y: 70 },
                "Lose",
                this
            ),
            new DroppableLocation(
                { x: 200, y: 70 },
                "Win",
                this
            )
        ]

        this.setSolved = setSolved
        this.states[this.stateMap["Intro"]].releasing(this.droppables[0])
        this.currentGameState = this.states[this.stateMap["Intro"]]
    }

    droppablePosition(droppable: DroppableLocation):Point2D {
        return {
            x: droppable.position.x,
            y: droppable.position.y
        }
    }

    droppableIntersects(draggable: Draggable, droppable: DroppableLocation) {
        let pDroppable = this.droppablePosition(droppable)
        let cmpx = draggable.position.x - pDroppable.x
        let cmpy = draggable.position.y - pDroppable.y
        if (
            cmpx < droppable.dimensions.x && cmpx > 0 &&
            cmpy < droppable.dimensions.y && cmpy > 0
        ) {
            return draggable.name == droppable.stateID
        }
        return false
    }

    touchingDroppable(draggable: Draggable): DroppableLocation | undefined {
        for (let i = 0; i < this.droppables.length; i++) {
            const droppable = this.droppables[i];
            if (this.droppableIntersects(draggable, droppable)) {
                return droppable
            }
        }
    }

    draw(p5: P5) {
        p5.fill(0, 0, 0)
        p5.noStroke()
        p5.text("Game Logic Area", this.position.x, this.position.y)
        for (let i = 0; i < this.droppables.length; i++) {
            const droppable = this.droppables[i];
            droppable.draw(p5)
        }
        p5.stroke(30, 30, 30)
        p5.strokeCap(p5.SQUARE)
        p5.fill(0, 0, 0)
        p5.triangle(
            this.position.x + 180,
            this.position.y + 35 - 5,
            this.position.x + 180,
            this.position.y + 35 + 5,
            this.position.x + 180 + 10,
            this.position.y + 35,
        )
        // p5.fill(0, 0, 0)
        p5.line(
            this.position.x + 95, 
            this.position.y + 35, 
            this.position.x + 180, 
            this.position.y + 35
        )

        p5.noStroke()
        p5.fill(0, 0, 0)
        p5.textSize(12)
        p5.text("play button pressed", this.position.x + 90, this.position.y + 20)

        p5.rotate(-0.2)
        let startStroke:Point2D = {x: 65, y: 80}
        let endStroke:Point2D = {x: 150, y: 80}

        p5.stroke(0, 0, 0)
        p5.line(
            this.position.x + startStroke.x, 
            this.position.y + startStroke.y, 
            this.position.x + endStroke.x, 
            this.position.y + endStroke.y
        )
        
        p5.noStroke()
        p5.fill(0, 0, 0)
        p5.textSize(12)

        p5.text(
            "lose button pressed", 
            this.position.x + startStroke.x - 10, 
            this.position.y + startStroke.y + 15
        )

        p5.stroke(30, 30, 30)
        p5.strokeCap(p5.SQUARE)
        p5.fill(0, 0, 0)
        p5.triangle(
            this.position.x + startStroke.x,
            this.position.y + startStroke.y - 5,
            this.position.x + startStroke.x,
            this.position.y + startStroke.y + 5,
            this.position.x + startStroke.x - 10,
            this.position.y + startStroke.y,
        )
        p5.rotate(0.2)

        startStroke = {x: 240, y: 43}
        endStroke = {x: 240, y: 65}

        p5.stroke(0, 0, 0)
        p5.line(
            this.position.x + startStroke.x, 
            this.position.y + startStroke.y,
            this.position.x + endStroke.x,
            this.position.y + endStroke.y
        )
        
        p5.noStroke()
        p5.fill(0, 0, 0)
        p5.textSize(12)

        p5.text(
            "win button pressed", 
            this.position.x + startStroke.x - 30, 
            this.position.y + endStroke.y - 10
        )

        p5.stroke(30, 30, 30)
        p5.strokeCap(p5.SQUARE)
        p5.fill(0, 0, 0)
        p5.triangle(
            this.position.x + endStroke.x - 5,
            this.position.y + endStroke.y - 5,
            this.position.x + endStroke.x + 5,
            this.position.y + endStroke.y - 5,
            this.position.x + endStroke.x,
            this.position.y + endStroke.y,
        )

    }
}


class GameViewButton {
    gameView: GameView

    dimensions: Point2D = {
        x: 118,
        y: 38
    }

    offset: Point2D = {
        x: 180,
        y: - 5
    }

    get position():Point2D {
        return {
            x: this.gameView.position.x + this.offset.x,
            y: this.gameView.position.y + this.offset.y
        }
    }

    pressed:boolean = false

    click() {
        this.pressed = false
    }

    constructor(gameView: GameView) {
        this.gameView = gameView
    }

    contains(point: Point2D): boolean {
        let cmpx = point.x - this.position.x
        let cmpy = point.y - this.position.y
        if (
            cmpx < this.dimensions.x && cmpx > 0 &&
            cmpy < this.dimensions.y && cmpy > 0
        ) {
            return true
        }
        return false
    }

    draw(p5: P5) {
        p5.noStroke()
        p5.fill(50, 50, 50)
        p5.rect(
            this.position.x,
            this.position.y,
            this.dimensions.x,
            this.dimensions.y,
            10,
            10,
            10,
            10
        )
        
        p5.fill("white")
        p5.text(this.gameView.running ? "Reset" : "Run Game", this.position.x + 10, this.position.y + 25)
    }
}

class GameView {

    runButton: GameViewButton
    running: boolean = false
    stateGraph: GameStateGraph
    dimensions: Point2D = {
        x: 300,
        y: 260
    }

    position: Point2D = {
        x: 10,
        y: 230
    }

    inViewPress: boolean = false

    contains(point: Point2D): boolean {
        let cmpx = point.x - this.gameSpacePosition.x
        let cmpy = point.y - this.gameSpacePosition.y
        if (
            cmpx < this.dimensions.x && cmpx > 0 &&
            cmpy < this.dimensions.y && cmpy > 0
        ) {
            return true
        }
        return false
    }

    get gameSpacePosition(): Point2D {
        return {
            x: this.position.x,
            y: this.position.y + 40
        }
    }

    constructor(stateGraph: GameStateGraph) {
        this.runButton = new GameViewButton(this)
        this.stateGraph = stateGraph
    }

    toggleRun() {
        this.running = !this.running
        if (!this.running) {
            this.stateGraph.changeState("Intro")
        }
    }

    pressed(position: Point2D) {
        if (this.runButton.contains(position)) {
            // this.runButton.pressed = true
            
        } else {
            if (this.contains(position)) {
                this.inViewPress = true
                
                this.stateGraph.currentGameState?.stateView.clicked(
                    position,
                    this,
                    this.stateGraph
                )
            }
        }
    }

    checkEndgame() {

    }

    released(position: Point2D) {
        if (this.runButton.contains(position)) {
            // this.runButton.click()
            this.toggleRun()
        } else {
            if (this.contains(position)) {
                this.inViewPress = false
            }
        }
    }

    draw(p5: P5) {
        p5.text("Play Game Here", this.position.x, this.position.y + 20)
        let gsp = this.gameSpacePosition

        this.runButton.draw(p5)

        p5.stroke(200, 200, 200)
        p5.rect(
            gsp.x,
            gsp.y,
            this.dimensions.x,
            this.dimensions.y
        )
        if (this.running == true) {
            this.stateGraph.currentGameState?.stateView.draw(p5, this)
        }
    }
}

export class StateMachineExperience implements ExhibitExperience {
    p5Container: HTMLDivElement
    setSolved: React.Dispatch<React.SetStateAction<boolean>> | undefined
    
    constructor (p5Container: HTMLDivElement) {
        document.body.style.position = "fixed"
        document.body.style.overflow = "hidden"
        document.body.style.width = "100%"
        document.body.style.height = "100%"

        this.p5Container = p5Container;

        let isMouse:boolean = false;
        let startPointer:TouchEvent | MouseEvent | undefined;
        let stateGraph: GameStateGraph = new GameStateGraph(this.setSolved)
        let gameView: GameView = new GameView(stateGraph)
        
        let selectedState: GameState | undefined

        const sketch = (p5: P5) => {
            p5.setup = () => {
                const canvas = p5.createCanvas(p5.windowWidth, p5.windowWidth);
                canvas.parent("p5-app");
                p5.windowResized()
            }
        
            p5.draw = () => {
                p5.background("white");
                p5.fill(200, 200, 200)
                p5.rect(
                    0, 0, 999999, 90
                )
                p5.fill(0,0,0)
                gameView.draw(p5)
                stateGraph.draw(p5)

                stateGraph.states.forEach((state) => {
                    state.draw(p5)
                });
            };
        
            p5.windowResized = (event?) => {
                p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
            }

            p5.mouseDragged = (event) => {
                dragged(event)
            }

            p5.mousePressed = (event) => {
                pressed(event)
            }

            p5.mouseReleased = (event) => {
                released(event)
            }

            p5.touchStarted = (event) => {
                pressed(event)
            }

            p5.touchEnded = (event) => {
                released(event)
            }

            p5.touchMoved = (event) => {
                dragged(event)
            }

            let dragged = (event) => {
                if (startPointer == undefined) {
                    return
                }

                let newPoint:Point2D
                if (isMouse) {
                    let startMouseTouch = startPointer as MouseEvent
                    let newMouseTouch = event as MouseEvent

                    newPoint = { 
                        x: newMouseTouch.clientX - startMouseTouch.clientX,
                        y: newMouseTouch.clientY - startMouseTouch.clientY
                    }

                    selectedState?.dragging(newPoint)
                } else {
                    let startMouseTouch = startPointer as TouchEvent
                    let newMouseTouch = event as TouchEvent

                    newPoint = { 
                        x: newMouseTouch.touches[0].clientX - startMouseTouch.touches[0].clientX,
                        y: newMouseTouch.touches[0].clientY - startMouseTouch.touches[0].clientY
                    }

                    selectedState?.dragging(newPoint)
                }
            }

            let pressed = (event) => {

                if (event instanceof MouseEvent) {
                    startPointer = event as MouseEvent;
                    isMouse = true;
                } else {
                    startPointer = event as TouchEvent;
                    isMouse = false;
                }

                let touchPosition:Point2D
                if (isMouse) {
                    // let startMouseTouch = startPointer as MouseEvent
                    let newTouch = event as MouseEvent
                    touchPosition = {
                        x: newTouch.clientX,
                        y: newTouch.clientY
                    }
                    gameView.pressed(touchPosition)
                    if (stateGraph.checkEndgame() && this.setSolved != undefined) {
                        this.setSolved(true)
                        ExhibitStorage.exhibit_three = true
                    }
                } else {
                    // let startTouch = startPointer as TouchEvent
                    let newTouch = event as TouchEvent
                    touchPosition = {
                        x: newTouch.touches[0].clientX,
                        y: newTouch.touches[0].clientY
                    }
                    gameView.pressed(touchPosition)
                    if (stateGraph.checkEndgame() && this.setSolved != undefined) {
                        this.setSolved(true)
                    }
                }
                for (let i = 0; i < stateGraph.states.length; i++) {
                    let state = stateGraph.states[i];
                    if (state.contains(touchPosition)) {
                        selectedState = state
                        return
                    }
                }
                
            }

            let released = (event) => {
                if (isMouse) {
                    let newTouch = event as MouseEvent
                    
                    if (selectedState != undefined) {
                        let droppable = stateGraph.touchingDroppable(selectedState as Draggable)
                        selectedState?.releasing(droppable)
                    } else {
                        gameView.released({
                            x: event.clientX,
                            y: event.clientY
                        })
                    }
                } else {
                    let newTouch = event as TouchEvent

                    if (selectedState != undefined) {
                        let draggable = selectedState as Draggable
                        let droppable = stateGraph.touchingDroppable(draggable)
                        selectedState?.releasing(droppable)
                    } else {
                        if (event.touches?.count > 0) {
                            gameView.released({
                                x: event.touches[0].clientX,
                                y: event.touches[0].clientY
                            })
                        }
                    }
                }
                selectedState = undefined
                
            }

        }
        
        var myP5 = new P5(sketch);
    }

    

    exhibitStorage: ExhibitStoragePrototype = ExhibitStorage;
}
import * as THREE from "three";

const vec2_0 = new THREE.Vector2();

export function createImageMesh(texture: THREE.Texture, material: THREE.Material, scale = 1, offset = vec2_0) {
    const {image} = texture as {image: HTMLImageElement};
    const width = image.naturalWidth * scale;
    const height = image.naturalHeight * scale;
    const geometry = new THREE.PlaneGeometry(width, height, 1, 1)
    const mesh = new THREE.Mesh(geometry, material);
    const container = new THREE.Object3D();

    mesh.position.set(offset.x * width, offset.y * height, 0);
    container.add(mesh);

    return container;
}

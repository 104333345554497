import { useMediaQuery, createTheme, Grid, Button } from "@mui/material";
import { getDesignTokens } from "AppGlobals";
import { Sectional } from "layout/Sectional";
import React from "react";


export const GameJamWidget: React.FC = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
        getDesignTokens(
        prefersDarkMode ? "dark" : "light")
        ),
        [
        prefersDarkMode ? "dark" : "light"
        ]
    );
    
    let color = prefersDarkMode ? "0%": "65%";


    return (
        <Sectional title="Here's How to Join:">
            <h2>To join on the jam, press these buttons and please sign up on BOTH websites</h2>
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <Button href="https://itch.io/jam/made-at-the-made" formTarget="" style={ { width:"100%", margin: "0 0 0 0" } }>
                        <img width="100%" src="img/sponsor/itchio-logo-white.svg" style={{ color: "#fff", filter: "invert(" + color + ")", }}  alt="Itch.io" />
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button href="https://www.eventbrite.com/e/made-the-made-game-jam-tickets-636964476897" formTarget="" style={ { width:"100%", margin: "0 0 0 0" } }>
                        <img width="100%" src="img/sponsor/logo-wordmark-white.png" style={{ color: "#fff", filter: "invert(" + color + ")", }} alt="EventBrite" />
                    </Button>
                </Grid>
            </Grid>
        </Sectional>
    );
}

import { Sectional } from "layout/Sectional"
import Splash, {  SplashContent } from "layout/Splash"
import React from "react"

type MembershipItem = {
    display_name: string,
    amount: string,
    perks: string[],
    image: string
}

type MembershipDescriptionProperties = {
    memberships: MembershipItem[]
}

export const MembershipDescriptionWidget: React.FC<MembershipDescriptionProperties> = ({ memberships }) => {
    const columns:React.ReactElement[] = [];
    const memberCount = memberships.length;

    for (let _i = 0; _i < memberCount; _i++) {
        const memberData = memberships[_i];
        const perks = memberData.perks;
        const perkElements:React.ReactElement[] = [];

        for (let _j = 0; _j < perks.length; _j++) {
            perkElements.push(
                <li key={_j}>{ perks[_j] }</li>
            )
        }
        
        columns.push(
            <div key={_i}>
                <h4>{ memberData.display_name }: { memberData.amount }</h4>
                <Splash align="right" image={ memberData.image }>
                
                    <SplashContent>
                        <ul>
                            { perkElements }
                        </ul>
                        {/* <Button href="https://store.themade.org">Go to Store</Button> */}
                    </SplashContent>
                </Splash>
            </div>
        );
    }
    
    return (
        <>
            <Sectional>
            {/* <Row>
                <Col>
                    { columns }
                </Col>
            </Row> */}
                
            </Sectional>
        </>
    );
}

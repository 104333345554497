/*
Based on QuoteSplash.tsx
This widget adds a line of text to the page
Title adds uses the title style.
Subtitle uses the subtitle style. 
*/

import React, { ReactNode } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { theme } from 'layout/Themes';
import { useMediaQuery } from '@mui/material';
import { getDesignTokens } from 'AppGlobals';

type QuoteProperties = {
  children: ReactNode | undefined
}

export const Quote: React.FC<QuoteProperties> = ({children}) => {
  const scaledHeight = { xs: "700px", sm: "500px", md: "800px", lg: "800px" };

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(
    getDesignTokens(
      prefersDarkMode ? "dark" : "light")
    ),
    [
      prefersDarkMode ? "dark" : "light"
    ]
  );

  return (
      <Box sx={{ 
        zIndex: "-999", 
        display: "block",
        height: "100%"
      }}>
        <Container maxWidth="lg">
          <Box sx={
            {
              zIndex: "200",
              display: "block",
              alignItems: "center",
              padding: "20px",
              width: "100%",
              background: theme.palette.background.default
            }
          }>
            { children }
          </Box>
        </Container>
      </Box>
  )
}

export const QuoteTitle: React.FC<{ children: ReactNode | undefined }> = ({children}) => {
  return (
    <ThemeProvider theme={ {} }>
      <Typography variant="h2">
        { children }
      </Typography>
    </ThemeProvider>
  );
}

export const QuoteSubtitle: React.FC<{ children: ReactNode | undefined }> = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h6" gutterBottom>
        { children }
      </Typography>
    </ThemeProvider>
  );
}

import { Button, createTheme, Grid, Typography, useMediaQuery } from "@mui/material";
import { getDesignTokens } from "AppGlobals";
import { Image, ImageButton, ImageMarked, ImageSrc } from "elements/ImageButton";
import { Sectional } from "layout/Sectional";
import React from "react";
import { Link } from "react-router-dom";

type CommunityEvent = {
  link: string;
  image: string;
  name: string
}

type CommunityProperties = {
  title: string,
  communityEvents:CommunityEvent[]
}

export const CommunityWidget: React.FC<CommunityProperties> = ({ title, communityEvents }) => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(
    getDesignTokens(
      prefersDarkMode ? "dark" : "light")
    ),
    [
      prefersDarkMode ? "dark" : "light"
    ]
  );

  return (
      <Sectional title={ title }>
        <Grid container spacing={2}>
          {
            communityEvents.map((event) => {
              return (
                <Grid item xs={12} sm={6}>
                  <ImageButton focusRipple
                    
                    href={ event.link }
                    style={{
                      width: "100%",
                    }}>
                      <ImageSrc style={{ backgroundImage: `url(${event.image})` }} />
                      <Image>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          color="inherit"
                          sx={{
                            position: 'relative',
                            p: 4,
                            pt: 2,
                            pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                          }}
                        >
                          { event.name }
                          <ImageMarked className="MuiImageMarked-root" />
                        </Typography>
                      </Image>
                  </ImageButton>
              </Grid>
              )
              }
            )
          }
          
        </Grid>
      </Sectional>
  );
}

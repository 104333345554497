import { Grid } from "@mui/material"
import { Sectional } from "layout/Sectional"
import React from "react"
import { useState } from "react"

export const MailingListWidget: React.FC = () => {

    let [emailValue, setEmailValue] = useState('')
    let [fNameValue, setFNameValue] = useState('')
    let [lNameValue, setLNameValue] = useState('')
    
    return (
        <Sectional>
            <h2>
                    Sign up for our mailing list!
            </h2>
            <Grid container spacing={2}>
                
                <Grid item xs={6}>
                    
                    <p>
                        Are you interested in getting video game history right to your inbox? Or maybe some more ideas on how you can inject game development techniques or design into your practice? Maybe you just want to keep on top of educational offerings for yourself or your children? We've got it all! We try to make our newsletter educational and relevant to your busy life. Just add your info to the form right here!
                    </p>
                </Grid>
                <Grid item xs={ 6 }>
                    <form action="https://themade.us12.list-manage.com/subscribe/post" method="POST" noValidate>
                        <input type="hidden" name="u" value="efa463dd16aa902cf54a317bb"/>
                        <input type="hidden" name="id" value="3fcce32be7"/>
                        <p>
                            <label style={{fontFamily: "PixelEmulator"}} htmlFor='MERGE1'>
                                First name
                                <input 
                                    style={{marginLeft: "10px"}}
                                    type="text" 
                                    name="FNAME" 
                                    id="MERGE1" 
                                    value={fNameValue} 
                                    onChange={ (e)=>{setFNameValue(e.target.value)} } 
                                />
                            </label>
                        </p>
                        <p>
                            <label style={{fontFamily: "PixelEmulator"}} htmlFor='MERGE2'>
                                Last name
                                <input 
                                    style={{marginLeft: "10px"}}
                                    type="text" 
                                    name="LNAME" 
                                    id="MERGE2" 
                                    value={lNameValue} 
                                    onChange={ (e)=>{setLNameValue(e.target.value)} } 
                                />
                            </label>
                        </p>
                        <p>
                            <label style={{fontFamily: "PixelEmulator"}} htmlFor='MERGE0'>
                                Email
                                <input 
                                    style={{marginLeft: "10px"}}
                                    type="email" 
                                    name="EMAIL" 
                                    id="MERGE0"
                                    value={emailValue} 
                                    onChange={ (e)=>{setEmailValue(e.target.value)} } 
                                    autoCapitalize="off" 
                                    autoCorrect="off"
                                    /> 
                            </label>
                        </p>
                        <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/>

                        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
                            <label htmlFor="b_name">Name: </label>
                            <input type="text" name="b_name" tabIndex={ -1 } value="" placeholder="Freddie" id="b_name"/>

                            <label htmlFor="b_email">Email: </label>
                            <input type="email" name="b_email" tabIndex={ -1 } value="" placeholder="youremail@gmail.com" id="b_email"/>

                            <label htmlFor="b_comment">Comment: </label>
                            <textarea name="b_comment" tabIndex={ -1 } placeholder="Please comment" id="b_comment"></textarea>
                        </div>
                    </form>
                </Grid>
            </Grid>
        </Sectional>
    )
}
  
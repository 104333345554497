import { Box, Grid, Link, Typography } from '@mui/material';
import { Sectional } from 'layout/Sectional';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type VisitProperties = {
    data: {
        title: string | "Visit";
        content: {
            address: string,
            hours: string,
            admissions: string
        },
        image: string,
        hours: string,
        admissions: string,
        directions: {
            google_maps: string;
            address: string;
            city: string;
            state: string;
            zip: string;
            parking: string;
            description: string;
        }
    }
}

function formatString(s: string, ...args: any[]) {
    return s.replace(/{([0-9]+)}/g, function (match, index) {
      return typeof args[index] == 'undefined' ? match : args[index];
    });
  };

export const VisitWidget: React.FC<VisitProperties> = ({ data }) => {

    let params = useParams();
    var i = 0;

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hours, setHours] = useState([]);
    const [admissions, setAdmissions] = useState([]);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Cache-Control', 'no-cache');
    requestHeaders.set('Pragma', 'no-cache');
    requestHeaders.set('Expires', '0');
  
    useEffect(() => {
      fetch(data["hours"], { 'headers': requestHeaders })
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setHours(result);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
        fetch(data["admissions"], { 'headers': requestHeaders })
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setAdmissions(result);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
    }, [])

    const renderedHours = hours["hours"];
    const hoursTemplate: string = hours["template"];
    const renderedAdmissions = admissions["admissions"];

    if (renderedHours && renderedAdmissions) {
        var id = 0;
        return (
            <>
                <Sectional title={data.title}>
                    <Grid container spacing={2}>
                        <Grid item md={8} lg={9}>
                            <Box sx={ { 
                                background: 'url("' + data.image + '")',
                                backgroundSize: "cover",
                                display: { xs: "none", sm: "none", md: "block" },
                                height: "500px",
                                width: "100%"
                            } }></Box>
                        </Grid>
                        <Grid item xs={12} sm={5} md={4} lg={3}>
                            <Typography variant="h5">{ data.content.address }</Typography>
                            <div>
                                <Link href={ data.directions.google_maps }>
                                    { data.directions.address }<br />
                                    { data.directions.city }, { data.directions.state } { data.directions.zip }
                                </Link>
                                <br />
                                <br />
                                <Typography variant="h5">{ data.content.hours }</Typography>
                                {
                                    renderedHours.map((hour) => {
                                        return <span key={ hour.day }>
                                            { formatString(hoursTemplate, hour.day, hour.start_time, hour.end_time) }
                                            <br />
                                        </span>
                                    })
                                }
                                <br />
                                <Typography variant="h5" >{ data.content.admissions }</Typography>
                                {
                                    renderedAdmissions.types.map((admission) => {
                                        return <span key={ admission.type }>
                                            <Link href="https://store.themade.org">{ admission.type } { admission.cost }</Link><br />
                                        </span>
                                    })
                                }
                                <p>
                                    {/* { data.admissions.description } */}
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </Sectional>
            </>
        )
    } else {
        return <></>
    }
}
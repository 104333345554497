export class ExhibitStoragePrototype {

    public get exhibit_one() {
        return (localStorage.getItem("exhibit_one") =="true");
    }

    public set exhibit_one(solved:boolean | undefined) {
        localStorage.setItem("exhibit_one", String(solved))
    }

    public get exhibit_two() {
        return (localStorage.getItem("exhibit_two") =="true");
    }

    public set exhibit_two(solved:boolean | undefined) {
        localStorage.setItem("exhibit_two", String(solved))
    }

    public get exhibit_three() {
        return (localStorage.getItem("exhibit_three") =="true");
    }

    public set exhibit_three(solved:boolean | undefined) {
        localStorage.setItem("exhibit_three", String(solved))
    }

}

export let ExhibitStorage:ExhibitStoragePrototype;
ExhibitStorage = new ExhibitStoragePrototype()